import * as Cookies from 'js-cookie'
import moment from "moment"
import _ from 'lodash';

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { withRouter, Route, Redirect, Switch, Link } from 'react-router-dom';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import { withTracking } from 'react-tracking';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Container, Col, Row,
} from 'reactstrap';
import { Alert as MuiAlert, Button, Card as MuiCard, CardContent, DialogActions, DialogContent, LinearProgress, Stack, Typography, useMediaQuery, useTheme, Box, Snackbar, IconButton } from '@mui/material'
import { DialogTitleWithCloseButton, ResponsiveDialog } from '@ei/components/dialog';
import styled from 'styled-components';

import LoadingSpinner from '../../components/Loader';
import Dashboard from '../Dashboard';
import AdminStats from '../SiteAdmin/AdminStats';
import ReviewsIframe from './ReviewsIframe';
import VisitorStats from '../SiteAdmin/VisitorStats';
import { isChecklistCompleted, completedItemCount, requiredListItems } from './GetStarted';

import useAuth from "../../pages/Authentication/useAuth";
import usePrevious from '../../helpers/usePrevious';
import { checkLessThanMinimumMonetaryBalance, checkLowMonetaryBalance, checkLessThanMinimumLeadBalance, checkLowLeadBalance } from 'utils/leadCreditBalance';
import servicesIcon3 from "../../assets/images/services-icon/01.png";
import { bp } from '../../utils/mq';
import { Close } from '@mui/icons-material';

import { authHost } from "../Authentication/Login"

const InviteUpload = lazy(() => import('./InviteUpload'));
const InviteTest = lazy(() => import('./InviteTest'));
const Invitations = lazy(() => import('./Invitations'));
const InvitationPreview = lazy(() => import('./InvitationPreview'));
const Profile = lazy(() => import('./Profile'));
const Analytics = lazy(() => import('./Analytics'));
const GetStarted = lazy(() => import('./GetStarted'));
const ReviewCollection = lazy(() => import('./ReviewCollection'));
const ReviewCollectionAutomatic = lazy(() => import('./ReviewCollectionAutomatic'));
const ReviewExport = lazy(() => import('./ReviewExport'));
const Admin = lazy(() => import('./Admin/index'));
const PricePerLeadCalculator = lazy(() => import('./PricePerLeadCalculator'));
const Widgets = lazy(() => import('./Widgets'));
const Badges = lazy(() => import('./MarketingAssets/Badges'));
const Awards = lazy(() => import('./Awards/index'));
const Evidence = lazy(() => import('./Evidence'));
const ImpactMetrics = lazy(() => import('./ImpactMetrics'));
const EmailWidgets = lazy(() => import('./EmailWidgets'));
const SurveyPreview = lazy(() => import('./SurveyPreview'));
const Reviews = lazy(() => import('./Reviews'));
const Notifications = lazy(() => import('./Notifications'));
const UserManagement = lazy(() => import('./UserManagement'));
const Categories = lazy(() => import('./Categories'));

// Leads
const LeadsIndex = lazy(() => import('./Leads/LeadsIndex'));
const LeadsPreferences = lazy(() => import('./LeadsPreferences/index'));
const LeadsPrices = lazy(() => import('./LeadsPrices'));
const LeadReplyTemplates = lazy(() => import('./LeadReplyTemplates'));

// Prospects
const BuyerIntentIndex = lazy(() => import('pages/SiteAdmin/BuyerIntent/BuyerIntentIndex'));
const BuyerIntentPreferences = lazy(() => import('pages/SiteAdmin/BuyerIntent/BuyerIntentPreferences'));

const PricingPlansPage = lazy(() => import('./PricingPlansPage'));

// Billing
const BillingPage = lazy(() => import('./BillingPage'));
const PurchaseCredits = lazy(() => import('./PurchaseCredits'));

const AdvancedSettingsPage = lazy(() => import('./AdvancedSettingsPage'));
const HealthCheck = lazy(() => import('./HealthCheck'));

const ProductAdmin = ({
  productPending,
  reviewsPending,
  invitesPending,
  leadsPending,
  products,
  product,
  reviews,
  match,
  location,
  dispatchConfigRequest,
  dispatchProductsRequest,
  dispatchInvitesRequest,
  dispatchGetProduct,
  dispatchReviewsRequest,
  dispatchLeadsRequest,
  tracking,
  dispatchSubmit,
}) => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser?.isAdmin

  const prevProduct = usePrevious(product);
  const [topupReminderOpen, setTopupReminderOpen] = useState(false)
  const [freeLeadsReminderOpen, setFreeLeadsReminderOpen] = useState(false)
  const [softAnnouncementConfig, setSoftAnnouncementConfig] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  })
  const theme = useTheme();
  const dialogButtonFullWidth = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(
    function getProductRelatedData() {
      if (
        // Have products from API, or slug in URL
        (products || match.params.slug)
        // Changed to a different product
        && (!prevProduct || match.params.slug !== prevProduct.slug)
      ) {
        const slug = match.params?.slug || product?.slug || products[0]?.slug;

        if (slug) {
          !productPending && dispatchGetProduct(slug);

          if (slug !== 'add-listing') {
            !reviewsPending && dispatchReviewsRequest({ slug });
            !invitesPending && dispatchInvitesRequest({ slug });
            !leadsPending && dispatchLeadsRequest({ slug });
          }
        }

      }
    },
    [match.params.slug, product, products]
  );

  useEffect(
    function trackProductUpdates() {
      if (product && prevProduct?.slug === product.slug) {
        tracking.trackEvent({ event: 'update-product' });
      }
    },
    [product]
  );

  useEffect(
    function ensureTopupDialogClosed() {
      if (
        window.location.hash
        && window.location.hash === "#details--topup"
        && topupReminderOpen
      ) {
        setTopupReminderOpen(false)
      }
    },
    [topupReminderOpen]
  )

  if (!products || productPending) {
    return (
      <div style={{ height: '80vh' }}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          containerId="pendingProductAdminToast"
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100%', width: '100%' }}>
          <LoadingSpinner />
        </Box>
      </div>
    );
  }

  // Always store their current plan so we can track a change to it later if need be. Expire early.
  if (!productPending && !!product && !Cookies.get(`${product.supabaseId}_Premium`)) {
    Cookies.set(`${product.supabaseId}_Premium`, product.isPremium, {
      expires: moment().add(1, 'minute').toDate(),
      domain: document.location.hostname,
    })
  }

  //  No (actual) products found.
  if (products.length <= 1 && !product) {
    window.location.replace(encodeURI(`${authHost}/account/`))
  }

  const onlyProduct = products[0];

  // No slug, but have exactly one (actual) product.
  if (!match.params.slug && products.length === 2) {
    if (!isChecklistCompleted(product, reviews?.data) && products.length > 1 && !product) {
      return <Redirect to={`/products/${match.params.slug || products[0].slug}/get-started`} />
    }

    return <Redirect to={`/products/${onlyProduct.slug}/dashboard`} />;
  }

  // Customer has at least one actual product, but no product data could be fetched.
  if (products.length > 1 && !product) {
    // ?
    if (match.params.slug === 'add-listing') {
      window.location.replace(encodeURI(`${authHost}/account/`))
    }

    if (location.pathname) return <Redirect to={location.pathname} />;
    return <Redirect to={`/products/${match.params.slug || onlyProduct.slug}/dashboard`} />;
  }

  const alertExceptions = ['hubmis', 'thinkthink']
  const { isPremium, unlimitedLeads = false, freeLeadCount = 0, paidLeadCount = 0, pricePerLead, pricePerLeadSince = null, leadCount = 0 } = product || {};

  const isLeadBalanceUser = !!pricePerLead && pricePerLead > 0
  const isFreePlan = !isPremium
  const isPowerPlan = isPremium && unlimitedLeads
  const isLegacyProPlan = isPremium && !unlimitedLeads && !isLeadBalanceUser
  const isNewProPlan = isPremium && !unlimitedLeads && isLeadBalanceUser

  const usedEnoughFreeLeads = freeLeadCount < 3 && leadCount > 0
  const noFreeLeadsLeft = freeLeadCount === 0 && leadCount > 0
  const freeLeadsLeft = !noFreeLeadsLeft
  const noFreeLeadsUsed = freeLeadCount === 3 && leadCount === 0

  const leadBalance = freeLeadCount + paidLeadCount
  const monetaryBalance = product?.leadCreditBalance || 0
  const isLowBalance = isFreePlan ? usedEnoughFreeLeads : isLeadBalanceUser ? checkLowLeadBalance(paidLeadCount) : checkLowMonetaryBalance(monetaryBalance)
  const isLessThanMinimumBalance = isFreePlan ? noFreeLeadsLeft : isLeadBalanceUser ? checkLessThanMinimumLeadBalance(paidLeadCount) : checkLessThanMinimumMonetaryBalance(monetaryBalance)

  // Redirect old price list and old purchase credits to new pages.
  if (location.pathname === `/products/${product.slug}/leads/purchase`) {
    return <Redirect to={`/products/${product.slug}/billing#details--balance`} />
  }

  // Redirect reply templates to leads index
  if (location.pathname === `/products/${product.slug}/leads/templates`) {
    return <Redirect to={`/products/${product.slug}/leads`} />
  }

  if (location.pathname === `/products/${product.slug}/leads/prices`) {
    return isLeadBalanceUser ? <Redirect to={`/products/${product.slug}/billing#details--balance`} /> : <Redirect to={`/products/${product.slug}/billing#details--prices`} />
  }

  /**
   * We have two dialog boxes: one to remind pro users to top-up, and one to remind any user who was awarded free leads to upgrade to pro (or top-up if they are already are on the pro plan and have their gift leads expiring).
   * While the new lead bundles are in pilot mode, we want to:
   * 1. Keep around the top-up dialog box for legacy pro users using their monetary balance. (no change)
   * 2. Add a new case for pilot pro users where their dialog and copy is determined by their lead balance. (new)
   * 3. Keep the free leads dialog as-is.
   */
  let showTopUpDialog = false
  let showFreeLeadsDialog = false

  // First, let's handle the free leads dialog. Do we show it or no?
  // if (!isAdmin && !alertExceptions.includes(product.slug)) {
  if (!alertExceptions.includes(product.slug) && leadCount > 0) {
    let freeLeadsRemainingOld = Cookies.get(`${product.supabaseId}_FreeLeadsReminder`)
    const isPremiumOld = Cookies.get(`${product.supabaseId}_Premium`)
    const wasFreePlan = isPremiumOld && isPremiumOld === "false"

    // Cookie exists so we know this is a subsequent visit.
    if (freeLeadsRemainingOld) {
      // Were they awarded more leads since last visit? If so, no need to show the dialog!
      if (freeLeadCount > Number(freeLeadsRemainingOld)) {
        Cookies.remove(`${product.supabaseId}_FreeLeadsReminder`, {
          domain: document.location.hostname,
        })
        freeLeadsRemainingOld = false
      }

      if (
        // Check that we didn't set this flag as false above.
        freeLeadsRemainingOld
        // Fewer leads remaining now?
        && (
          (isFreePlan && freeLeadCount < Number(freeLeadsRemainingOld) && !paidLeadCount)
          // Additional condition for pro plan: they still have at least 1 free lead left! Why? Because pro users when they exhaust their lead balance then use their monetary balance to get leads. That is handled in the top-up dialog box.
          || (isLegacyProPlan && freeLeadsLeft && freeLeadCount < Number(freeLeadsRemainingOld))
          // Similar logic for new pro plan. Must have at least 1 lead left, free leads remaining must equal lead balance. (When this is not true, it means their lead balance is affected by more than just free leads and we need to show them messaging around low balance and not free leads).
          || (isNewProPlan && freeLeadsLeft && freeLeadCount < Number(freeLeadsRemainingOld) && isLessThanMinimumBalance)
        )
      ) {
        showFreeLeadsDialog = true
      }
    }
    //  No cookie set so we know this is a first visit *where* the free leads used is beyond our threshold.
    else if (freeLeadsRemainingOld === undefined) {
      if ((isFreePlan && usedEnoughFreeLeads) || ((isLegacyProPlan || isNewProPlan) && freeLeadsLeft && usedEnoughFreeLeads && isLessThanMinimumBalance)) {
        showFreeLeadsDialog = true
      }
    }

    // Lastly, let's do a check for plan change (free -> pro) or free leads exhaustion (pro).
    if (
      // They upgraded.
      (wasFreePlan && (isLegacyProPlan || isPowerPlan))
      // Exhausted all their free leads on a Pro plan. Show top-up dialog instead.
      || (isLegacyProPlan && noFreeLeadsLeft)
      // Exhausted all free leads and does not have any paid leads. Show top-up dialog instead.
      || (isNewProPlan && noFreeLeadsLeft && !paidLeadCount)
    ) {
      // Must pass the same path and domain options as at creation.
      Cookies.remove(`${product.supabaseId}_FreeLeadsReminder`, {
        domain: document.location.hostname,
      })
      showFreeLeadsDialog = false
    }
  }

  if (showFreeLeadsDialog && !freeLeadsReminderOpen) {
    Cookies.set(`${product.supabaseId}_FreeLeadsReminder`, freeLeadCount, {
      // Will be interpreted as days from create time.
      // Going aggressive here since we need to sell harder to customers who aren't paying us at all. And they are also customers who have less of a reason to log in to begin with!
      expires: 1,
      // Limit it to the current subdomain.
      domain: document.location.hostname,
    })
    setFreeLeadsReminderOpen(true)
  }

  const freeLeadsCrossSellLink = 'https://edtechimpact.com/pricing';
  const handleFreeLeadsReminderClose = () => {
    tracking.trackEvent({
      event: 'billing.free-leads.reminder.dismiss',
    })
    setFreeLeadsReminderOpen(false)
  }

  const trackFreeLeadsCrossSellLink = () => {
    tracking.trackEvent({
      event: 'billing.free-leads.reminder.use',
    })
    setFreeLeadsReminderOpen(false)
  }

  // Don't run for admins, or customers who are on the Power plan.
  // if (!isAdmin && !isPowerPlan && (!showFreeLeadsDialog && !freeLeadsReminderOpen && !Cookies.get("EIFreeLeadsReminder"))) {
  if (!isPowerPlan && (!showFreeLeadsDialog && !freeLeadsReminderOpen && !Cookies.get(`${product.supabaseId}_FreeLeadsReminder`)) && leadCount > 0) {
    // A cookie is set everytime there is a low or less than minimum balance. It auto expires in 7 days.
    if (Cookies.get(`${product.supabaseId}_TopUpReminder`)) {
      // If there is a cookie, we need to run some extra checks to determine if the modal should be shown again! This can happen when someone goes from low balance to less than min balance within a span of 7 days. They may have this cookie, but it's irrelevant because we need to show them a dialog again.
      const topupBalanceOld = Cookies.get(`${product.supabaseId}_EITopUpReminder`)
      // Check that the balance when the cookie was created still falls in the same category (whether that's low or minimum)

      // Low balance before. Less than min balance now.
      if ((isLegacyProPlan && checkLowMonetaryBalance(topupBalanceOld) && isLessThanMinimumBalance) || (isNewProPlan && checkLowLeadBalance(topupBalanceOld) && isLessThanMinimumBalance)) {
        showTopUpDialog = true
      }

      // They topped up and are now no longer in the same category (less than min -> low, or low -> no issues)
      if (
        ((isLegacyProPlan && monetaryBalance > topupBalanceOld) || (isNewProPlan && paidLeadCount > topupBalanceOld))
        && (
          ((checkLessThanMinimumMonetaryBalance(topupBalanceOld) && isLowBalance)
            || (checkLowMonetaryBalance(topupBalanceOld) && monetaryBalance >= 250))
          || ((checkLessThanMinimumLeadBalance(topupBalanceOld) && isLowBalance)
            || (checkLowLeadBalance(topupBalanceOld) && paidLeadCount > 3))
        )
      ) {
        // Delete the cookie.
        // Must pass the same path and domain options as at creation.
        Cookies.remove(`${product.supabaseId}_TopUpReminder`, {
          domain: document.location.hostname,
        })
      }
    } else {
      // If there is no cookie, we execute the simplest logic possible to determine if we need to show the dialog box.
      showTopUpDialog = (isLegacyProPlan || isNewProPlan) && (isLowBalance || isLessThanMinimumBalance) && noFreeLeadsLeft
    }
  }


  if (showTopUpDialog) {
    Cookies.set(`${product.supabaseId}_TopUpReminder`, isLeadBalanceUser ? paidLeadCount : monetaryBalance, {
      // Will be interpreted as days from create time.
      expires: 7,
      // Limit it to the current subdomain.
      domain: document.location.hostname,
    })
    setTopupReminderOpen(true)
  }

  const topUpLink = `/products/${product.slug}/billing/#details--${isLeadBalanceUser ? 'balance' : 'topup'}`;
  const handleTopupReminderClose = () => {
    tracking.trackEvent({
      event: 'billing.topup.reminder.dismiss',
    })
    setTopupReminderOpen(false)
  }

  const trackSoftAnnouncementLink = () => {
    tracking.trackEvent({
      event: 'soft-announcement.cta.click:compare-bundles',
    })
    handleSoftAnnouncementClose()
  }

  const trackTopUpLink = () => {
    tracking.trackEvent({
      event: 'billing.topup.reminder.use',
    })
  }

  // Soft announcements.
  const handleSoftAnnouncementOpen = () => {
    setSoftAnnouncementConfig({ ...softAnnouncementConfig, open: true })
  }

  const handleSoftAnnouncementClose = () => {
    setSoftAnnouncementConfig({ ...softAnnouncementConfig, open: false })

    tracking.trackEvent({
      event: 'soft-announcement.dismiss',
    })

    // Set a cookie so they don't see it again.
    const now = moment().utc()
    // 3 weeks from last date of launch/opt-in.
    const lastDate = moment("2023-08-15T23:59:59.999Z").utc()
    Cookies.set(`${product.supabaseId}_SoftAnnouncementRead`, true, {
      // Whatever number of days it is to 15 August 2023 from now.
      expires: Number(moment.duration(lastDate.diff(now)).asDays().toFixed(0)) || 1,
      domain: document.location.hostname,
    })
  }

  // Criteria will change with every announcement and its needs.
  if (pricePerLeadSince
    && moment(pricePerLeadSince).isAfter("2023-07-04T00:00:00.000Z")
    && moment(pricePerLeadSince).isBefore("2023-07-25T00:00:00.000Z")
    // Do not show this any longer after 3 weeks from the last opt-in date.
    && moment().isBefore("2023-08-15T23:59:59.999Z")
    && !Cookies.get(`${product.supabaseId}_SoftAnnouncementRead`)
    && !softAnnouncementConfig.open
    // Exclude products with unlimited leads.
    && !unlimitedLeads
  ) {
    handleSoftAnnouncementOpen()
  }



  return (
    <StyledProductAdmin fluid>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        containerId="defaultProductAdminToast"
      />

      {!product?.published &&
        <Container fluid className="mt-3">
          <Row>
            <Col>
              <MuiAlert severity="error" variant='standard'>
                Your product is currently unpublished pending review by the EdTech Impact team.
              </MuiAlert>
            </Col>
          </Row>
        </Container>
      }

      <ResponsiveDialog
        open={topupReminderOpen}
        onClose={handleTopupReminderClose}
      >
        <DialogTitleWithCloseButton
          onClose={handleTopupReminderClose}
        >{isLowBalance ? 'Keep lead generation active.' : isLessThanMinimumBalance ? 'Reactivate lead generation.' : null}</DialogTitleWithCloseButton>
        <DialogContent>
          {isLeadBalanceUser ? <Typography>Current balance: {leadBalance} {leadBalance === 1 ? "lead" : "leads"}.</Typography> : <Typography>Current balance: £{monetaryBalance}</Typography>}
          <Typography>
            {isLowBalance ? `You have a low balance right now. We recommend topping-up to ensure lead generation remains active for ${product.name}.` : null}
            {isLessThanMinimumBalance ? `You have a low balance right now. Lead generation is now turned off for ${product.name}. Top-up to turn it back on.` : null}
          </Typography>
        </DialogContent>
        <DialogActions sx={dialogButtonFullWidth ? { flexDirection: 'column', gap: 2 } : { flexDirection: 'row', gap: 2 }}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={handleTopupReminderClose}
            fullWidth={dialogButtonFullWidth}
          >Remind me later</Button>
          <Button
            variant='contained'
            color='primary'
            fullWidth={dialogButtonFullWidth}
            LinkComponent={Link}
            to={topUpLink}
            onClick={() => { trackTopUpLink(); setTopupReminderOpen(false) }}
          >Top-up</Button>
        </DialogActions>
      </ResponsiveDialog>

      <ResponsiveDialog
        open={freeLeadsReminderOpen}
        onClose={handleFreeLeadsReminderClose}
      >
        <DialogTitleWithCloseButton
          onClose={handleFreeLeadsReminderClose}
        >{noFreeLeadsLeft ? 'Lead generation turned off.' : 'Keep lead generation active!'}</DialogTitleWithCloseButton>
        <DialogContent>
          {noFreeLeadsLeft ?
            <>
              <Typography>You're popular! Your free leads have been used up. 🎉</Typography>
              <Typography>Upgrade to a <strong>Pro plan</strong> to resume lead generation for {product.name}.</Typography>
            </>
            : <>
              <Typography>You have {freeLeadCount} {freeLeadCount === 1 ? "free lead" : "free leads"} remaining.</Typography>
              {isFreePlan && <Typography>Upgrade to a <strong>Pro plan</strong> to ensure lead generation remains active for {product.name}.</Typography>}
              {(isLegacyProPlan || isNewProPlan) && <Typography>Top-up to ensure lead generation remains active for {product.name}.</Typography>}
            </>}
        </DialogContent>
        <DialogActions sx={dialogButtonFullWidth ? { flexDirection: 'column', gap: 2 } : { flexDirection: 'row', gap: 2 }}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={handleFreeLeadsReminderClose}
            fullWidth={dialogButtonFullWidth}
          >Remind me later</Button>
          {isFreePlan && <Button
            variant='contained'
            color='primary'
            fullWidth={dialogButtonFullWidth}
            href={freeLeadsCrossSellLink}
            onClick={() => { trackFreeLeadsCrossSellLink(); setFreeLeadsReminderOpen(false) }}
          >Upgrade</Button>}
          {(isLegacyProPlan || isNewProPlan) && <Button
            variant='contained'
            color='primary'
            fullWidth={dialogButtonFullWidth}
            LinkComponent={Link}
            to={topUpLink}
            onClick={trackTopUpLink}
          >Top-up</Button>}
        </DialogActions>
      </ResponsiveDialog>

      <Snackbar
        // 5 minutes
        autoHideDuration={300000}
        anchorOrigin={{ vertical: softAnnouncementConfig.vertical, horizontal: softAnnouncementConfig.horizontal }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="#000"
            onClick={handleSoftAnnouncementClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
        open={softAnnouncementConfig.open}
        onClose={handleSoftAnnouncementClose}
        message={<Typography mb={0}><strong>Power up!</strong> Introducing our new lead bundles with tailored pricing. <Link to={`/products/${product.slug}/billing#details--balance`} onClick={trackSoftAnnouncementLink}>Compare bundles</Link></Typography>}
        key={softAnnouncementConfig.vertical + softAnnouncementConfig.horizontal}
        disableWindowBlurListener={true}
      />

      {((isFreePlan && usedEnoughFreeLeads && freeLeadsLeft) || ((isLegacyProPlan || isNewProPlan) && usedEnoughFreeLeads && freeLeadsLeft && isLessThanMinimumBalance)) && !alertExceptions.includes(product.slug) ?
        <Container fluid className={!product?.published ? "" : "mt-3"}>
          <Row>
            <Col>
              <MuiAlert variant='standard' severity='warning'>Your free lead credits are nearing exhaustion. {isFreePlan && <>We recommend <a href={freeLeadsCrossSellLink} onClick={trackFreeLeadsCrossSellLink} target="_blank">upgrading</a> to our <strong>Pro plan</strong> to ensure lead generation remains active for {product.name}.</>}{(isLegacyProPlan || isNewProPlan) && <>We recommend <Link to={topUpLink} onClick={trackTopUpLink}>topping-up</Link> to ensure lead generation remains active for {product.name}.</>}</MuiAlert>
            </Col>
          </Row>
        </Container>
        : null}

      {/* Around ~10 free clients have monetary balance even though they are on the free plan. We should check for the balance as well here. */}
      {noFreeLeadsLeft && isFreePlan && checkLessThanMinimumMonetaryBalance(monetaryBalance) && !alertExceptions.includes(product.slug) ?
        <Container fluid className={!product?.published ? "" : "mt-3"}>
          <Row>
            <Col>
              <MuiAlert variant='standard' severity='error'>Lead generation is now turned off for {product.name}. <a href={freeLeadsCrossSellLink} onClick={trackFreeLeadsCrossSellLink} target="_blank">Upgrade to Pro</a> to turn it back on.</MuiAlert>
            </Col>
          </Row>
        </Container>
        : null}

      {((isLegacyProPlan || isNewProPlan) && isLowBalance && noFreeLeadsLeft) && !alertExceptions.includes(product.slug) ?
        <Container fluid className={!product?.published ? "" : "mt-3"}>
          <Row>
            <Col>
              <MuiAlert variant='standard' severity='warning'>Your lead credits are desperately low. We recommend <Link to={topUpLink} onClick={trackTopUpLink}>topping-up</Link> to ensure lead generation remains active for {product.name}.</MuiAlert>
            </Col>
          </Row>
        </Container>
        : null}

      {((isLegacyProPlan || isNewProPlan) && isLessThanMinimumBalance && noFreeLeadsLeft) && !alertExceptions.includes(product.slug) ?
        <Container fluid className={!product?.published ? "" : "mt-3"}>
          <Row>
            <Col>
              <MuiAlert variant='standard' severity='error'>Lead generation is now turned off for {product.name}. <Link to={topUpLink} onClick={trackTopUpLink}>Top-up</Link> to turn it back on.</MuiAlert>
            </Col>
          </Row>
        </Container>
        : null}

      {/* If they are a paid client and have low or minimum balance, do not show the onboarding checklist card. */}
      {!(isPremium && (isLowBalance || isLessThanMinimumBalance)) && !isChecklistCompleted(product, reviews?.data) && !location.pathname.includes('get-started') && location.pathname.endsWith(`/products/${product.slug}/dashboard`) &&
        <Container fluid className="mt-3">
          <Row>
            <Col lg={6} className='d-flex align-items-center'>
              <MuiCard variant='outlined'>
                <CardContent py={4}>
                  <Typography>
                    You've completed <b>{completedItemCount(product, reviews?.data)}</b> out of{" "}
                    <b>{requiredListItems(product).length}</b> <Link to={`/products/${product.slug}/get-started`}>onboarding steps</Link>.
                  </Typography>

                  <LinearProgress
                    value={(completedItemCount(product, reviews?.data) / requiredListItems(product).length) * 100}
                    variant='determinate'
                  />
                </CardContent>
              </MuiCard>
            </Col>
          </Row>
        </Container>
      }

      <Suspense fallback={<LoadingSpinner isPremium={isPremium} />}>
        <Switch>
          <AdminRoute
            path="/products/:slug/admin"
            exact
            render={() => <Admin isPremium={isPremium} />}
          />

          <AdminRoute
            path="/products/:slug/price-per-lead-calculator"
            exact
            render={() => <PricePerLeadCalculator />}
          />

          <AdminRoute
            path="/products/:slug/admin-stats"
            exact
            render={() => <AdminStats isPremium={isPremium} />}
          />

          <AdminRoute
            path="/products/:slug/visitor-stats"
            exact
            render={() => <VisitorStats isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug"
            exact
            render={() => <Redirect to={`/products/${product.slug}/dashboard`} />}
          />

          <Route
            path="/products/:slug/health-check/researched-impact"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="researched-impact" />}
          />
          <Route
            path="/products/:slug/health-check/compliance-safety"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="compliance-safety" />}
          />
          <Route
            path="/products/:slug/health-check/learning-engagement"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="learning-engagement" />}
          />
          <Route
            path="/products/:slug/health-check"
            exact
            render={() => <HealthCheck isPremium={isPremium} product={product} form="researched-impact" />}
          />
          <Route
            path="/products/:slug/dashboard"
            exact
            render={() => <Dashboard isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/get-started"
            exact
            render={() => reviews && <GetStarted product={product} reviews={reviews?.data} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/analytics"
            exact
            render={() => <Analytics product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/manual-collection"
            exact
            render={() => <ReviewCollection isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/automatic-collection"
            exact
            render={() => <ReviewCollectionAutomatic isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/export"
            exact
            render={() => <ReviewExport isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/survey"
            exact
            render={() => (
              <SurveyPreview product={product} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/profile"
            exact
            render={() => <Profile isPremium={isPremium} isAdmin={isAdmin} />}
          />
          <Route
            path="/products/:slug/impact-metrics"
            exact
            render={() => <ImpactMetrics isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/categories"
            exact
            render={() => <Categories isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/evidence"
            exact
            render={() => <Evidence />}
          />
          <Route
            path="/products/:slug/widgets"
            exact
            render={() => <Widgets product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews-page"
            exact
            render={() => (
              <ReviewsIframe product={product} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/marketing-assets"
            exact
            render={() => <Badges product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/awards"
            exact
            render={() => <Awards product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/email-widgets"
            exact
            render={() => (
              <EmailWidgets product={product} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/reviews/your-reviews"
            exact
            render={() => <Reviews isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/invitations"
            exact
            render={() => <Invitations isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/invite-email"
            exact
            render={() => <InvitationPreview isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/upload-invites"
            exact
            render={() => <InviteUpload isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/reviews/test-invite"
            exact
            render={() => <InviteTest isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/admin/notifications"
            exact
            render={() => <Notifications isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/admin/users"
            exact
            render={() => <UserManagement isPremium={isPremium} product={product} submit={dispatchSubmit} />}
          />
          <Route
            path="/products/:slug/leads"
            exact
            render={() => (
              <LeadsIndex isAdmin={isAdmin} isPremium={isPremium} />
            )}
          />
          <Route
            path="/products/:slug/leads/preferences"
            exact
            render={() => <LeadsPreferences isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/prices"
            exact
            render={() => <LeadsPrices isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/templates"
            exact
            render={() => <LeadReplyTemplates isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/leads/purchase"
            exact
            render={() => <PurchaseCredits isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/visitor-activity"
            exact
            render={() => <BuyerIntentIndex isAdmin={isAdmin} product={product} isPremium={isPremium} />}
          />
          <Route
            path="/products/:slug/visitor-activity/preferences"
            exact
            render={() => <BuyerIntentPreferences isAdmin={isAdmin} product={product} isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/subscription"
            exact
            render={() => <PricingPlansPage isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/billing"
            exact
            render={() => <BillingPage isPremium={isPremium} />}
          />

          <Route
            path="/products/:slug/admin/advanced/"
            exact
            render={() => <AdvancedSettingsPage isPremium={isPremium} />}
          />

        </Switch>
      </Suspense>
    </StyledProductAdmin>
  );
}


const AdminRoute = props => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.isAdmin) return null;
  return <Route {...props} />;
}

const StyledProductAdmin = styled(Container)`
  position: relative;
  margin-bottom: 3rem;
  padding: 0 0.25rem;

  @media ${bp.md} {
    padding: 0 0.75rem;
  }
`;

const mapStateToProps = state => {
  const { product, products, productPending, invitesPending, leadsPending, productsPending, updatePending, reviewsPending, reviews } = state.Product;
  const { preselectedSubscription } = state.App;
  return { preselectedSubscription, product, products, productPending, invitesPending, leadsPending, productsPending, updatePending, reviewsPending, reviews };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchConfigRequest: actions.configRequestAction,
      dispatchProductsRequest: actions.productsRequestAction,
      dispatchGetProduct: actions.getProduct,
      dispatchReviewsRequest: actions.reviewsRequestAction,
      dispatchInvitesRequest: actions.invitesRequestAction,
      dispatchLeadsRequest: actions.leadsRequestAction,
      dispatchSubmit: actions.updateProductAction,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTracking(),
)(ProductAdmin);
