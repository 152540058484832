import currency from "currency.js"

/**
 * @param {int} amount 
 * @returns {boolean}
 */
export const checkLowMonetaryBalance = (amount) => {
  return amount < 250 && amount >= 50
}

/**
 * @param {int} amount 
 * @returns {boolean}
 */
export const checkLessThanMinimumMonetaryBalance = (amount) => {
  return amount < 50
}

/**
 * @param {int} amount 
 * @returns {boolean}
 */
export const checkLowLeadBalance = (amount) => {
  return amount <= 2 && amount >= 1
}

/**
 * @param {int} amount 
 * @returns {boolean}
 */
export const checkLessThanMinimumLeadBalance = (amount) => {
  return amount < 1
}

export const getLeadsBought = (pricePerLead, amount) => {
  // Figure out how many leads to add given the amount, and update product.
  // This is taken straight as-is from the frontend. If that changes, this will need changing too.
  const MINIMUM_CREDITS = 5
  const tiers = [MINIMUM_CREDITS, MINIMUM_CREDITS * 2, MINIMUM_CREDITS * 3, MINIMUM_CREDITS * 4, MINIMUM_CREDITS * 5]
  const amounts = tiers.map((numberOfLeads, index) => {
    const discountBase = 10;
    const discountIncrement = 5;
    const discountPercent = index > 0 ? discountBase + ((index - 1) * discountIncrement) : false;
    const discountMultiplier = currency(discountPercent).divide(100)
    const baseAmount = currency(pricePerLead, { precision: 0 })
      .multiply(numberOfLeads)
    const finalAmount = baseAmount
      .multiply(
        currency(1.0).subtract(discountMultiplier)
      )
    return finalAmount.value
  })

  const amountIndex = amounts.findIndex((knownAmount) => knownAmount === amount)
  if (amountIndex === -1) {
    console.error("Invalid amount passed to getLeadsBought. No bundle exists for this amount!")
    // For debugging
    // console.log({ pricePerLead, amount, tiers, amounts, amountIndex })
  }
  return tiers[amountIndex]
}