import { takeLatest, delay, call, put, select, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from './constants';
import * as actions from './actions';
import { storePreselectedSubscriptionAction } from "store/app/actions";
import {
  selectProduct,
  selectProducts,
} from './selectors';
import { URLToFormData } from '../../utils/helpers';
import ApiService from '../../services/apiService';
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import axios from 'axios';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_TEST_MODE, VAT_RATE } from "../../config/settings";
import currency from 'currency.js';
import { isObject } from 'lodash';

const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function* configRequest() {
  try {
    const [config, planConfig] = yield all([
      call([ApiService, 'getConfig']),
      call([ApiService, 'getPlanConfig']),
    ]);
    yield put(actions.configRequestSuccessAction({ config, planConfig }));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.configRequestCompleteAction());
  }
}

export function* updateConfig(action) {
  scrollToTop();
  try {
    const config = yield call([ApiService, 'updateConfig'], action.payload);
    yield put(actions.updateConfigSuccessAction(config));
    toast.success("Saved!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.updateConfigCompleteAction());
  }
}

export function* getWordPressCategoryData() {
  try {
    const data = yield call([ApiService, "getWordPressCategoryData"]);
    yield put(actions.getWordPressCategoryDataSuccessAction(data));
  } catch (err) {
    toast.error("Error");
    console.error(err);
  } finally {
    yield put(actions.getWordPressCategoryDataCompleteAction());
  }
}

export function* productsRequest() {
  try {
    const result = yield call([ApiService, 'getUserItems'], 'product');
    yield put(actions.productsRequestSuccessAction(result));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.productsRequestCompleteAction());
  }
}

export function* getProductSaga(action) {
  const { slug } = action?.subscription || action;

  try {
    let product;
    if (slug === 'add-listing') {
      product = { slug: 'add-listing', name: '+ Add Listing', plan: 'free' };
    } else {
      product = yield call([ApiService, 'getUserProduct'], slug);
    }

    yield put(actions.getProductSuccess(product));
  } catch (error) {
    toast.error("Could not get product details.")
    console.log(error);
  } finally {
    yield put(actions.getProductComplete());
  }
}

export function* exportReviews() {
  try {
    const product = yield select(selectProduct);
    const reviewsExport = yield call([ApiService, 'reviewsExport'], { product: product.slug });
    const blob = new Blob([reviewsExport], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllReviews() {
  try {
    const reviewsExport = yield call([ApiService, 'allReviewsExport']);
    const blob = new Blob([reviewsExport], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "all-reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllProducts(action) {
  try {
    yield call([ApiService, 'allProductsExport'], action.payload);
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllLeads(action) {
  try {
    const reviewsExport = yield call([ApiService, 'allLeadsExport'], action.payload);
    const blob = new Blob([reviewsExport], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "all-leads.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllAnonUsers(action) {
  try {
    const anonUsersExport = yield call([ApiService, 'exportAllAnonUsers'], action.payload);
    const blob = new Blob([anonUsersExport], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "all-anon-users.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* createProduct(action) {
  scrollToTop();
  try {
    const { screenshots, logo } = action;
    let product = yield call([ApiService, 'createProduct'], action.product);

    if (!product || product.data?.error) {
      toast.error(product.data.error.description || "Error. Not created.");

    } else {

      if (screenshots) {
        const form = new FormData();
        let i = 0;

        for (const image of screenshots) {
          i++;
          const file = yield call(URLToFormData, [image.source]);
          form.append(`file${i}`, file);
        }
        product.images = yield call([ApiService, 'postScreenshots'], { form, slug: product.slug });
      }

      if (logo && logo.source) {
        const form = new FormData();
        const file = yield call(URLToFormData, [logo.source]);
        form.append('file', file);
        product.logo = yield call([ApiService, 'postLogo'], { form, slug: product.slug });
      }

      if (product.images || product.logo) {
        product = yield call([ApiService, 'updateProduct'], { product });
      }

      yield put(actions.createProductSuccessAction(product));
      yield put(push(`/products/${product.slug}/profile`));
      toast.success("Listing created!");
    }

  } catch (err) {
    console.log(err);
    toast.error(err.description || "Error. Not created.");
  } finally {
    yield put(actions.createProductCompleteAction());
  }
}

export function* deleteLead(action) {
  try {
    yield call([ApiService, 'deleteLead'], { TYPE: action.lead.TYPE });
    yield put(actions.deleteLeadSuccessAction(action.lead));
    toast.success("Deleted");
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.deleteLeadCompleteAction());
  }
}

export function* uploadEvidence(action) {
  try {
    if (action.payload.length) {
      const products = yield select(selectProducts);
      const product = yield select(selectProduct);
      const { slug } = product;
      const files = action.payload.map(study => study.file.name);
      const signedUrls = yield call([ApiService, 'postEvidence'], { files, slug });

      let i = 0;
      for (const study of action.payload) {
        const signedUrl = signedUrls[i];
        const formData = new FormData();
        Object.entries(signedUrl.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", study.file);
        yield call(axios.post, signedUrl.url, formData);
        i++;
      }

      const newCaseStudies = action.payload.map((study, index) => ({
        link: {
          type: 'file',
          location: signedUrls[index].fields.key.split('/')[signedUrls[index].fields.key.split('/').length - 1],
        },
        schoolName: study.title,
        date: study.date ? moment(study.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
        location: '',
        type: study.type,
        // New case studies so we can assume create date is 'now.'
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      }));

      const newProduct = {
        ID: product.ID,
        TYPE: product.TYPE,
        slug: product.slug,
        caseStudies: product.caseStudies ? [...product.caseStudies, ...newCaseStudies] : [...newCaseStudies],
      }

      const result = yield call([ApiService, 'updateProduct'], { product: newProduct });

      const updatedProducts = products.map(p => {
        if (p.slug === result.slug) return result;
        return p;
      });
      yield put(actions.productsRequestSuccessAction(updatedProducts));
      yield put(actions.getProductSuccess(result));
    }

    toast.success("Saved!");

  } catch (error) {
    let errorMessage = "Error. Not saved."
    if (error.response && error.response.data.status >= 400 && error.response.data.status <= 599) {
      errorMessage = error.response.data.error.description
    }
    toast.error(errorMessage);
    console.error({ error });
  } finally {
    yield put(actions.completeUpdateProduct());
  }
}

export function* deleteEvidence(action) {
  try {
    const products = yield select(selectProducts);
    const { slug } = yield select(selectProduct);
    const result = yield call([ApiService, 'deleteEvidence'], { slug, id: action.payload });
    const updatedProducts = products.map(p => {
      if (p.slug === result.slug) return result;
      return p;
    });
    yield put(actions.productsRequestSuccessAction(updatedProducts));
    yield put(actions.getProductSuccess(result));
    toast.success("Deleted!");

  } catch (err) {
    console.log(err);
    toast.error("Error. Not deleted.");
  } finally {
    yield put(actions.completeUpdateProduct());
  }
}

export function* updateProduct(action) {
  scrollToTop();

  const { screenshots, logo } = action;

  try {
    const product = yield select(selectProduct);
    if (!product) return;

    const { slug } = product;
    const newProduct = {
      ...action.product,
      slug,
    };

    if (screenshots) {
      const form = new FormData();
      let i = 0;

      for (const image of screenshots) {
        i++;
        const file = yield call(URLToFormData, [image.source]);
        form.append(`file${i}`, file);
      }
      const res = yield call([ApiService, 'postScreenshots'], { form, slug });
      newProduct.images = res
      // Hacky, but mark all uploads as done, remove name and size details so RUG doesn't show it.
      // ? What if the postScreenshots call fails?
      newProduct.screenshots.forEach((s, index) => {
        s.done = true
        // No longer a blob! Switch to URL.
        s.source = res[index]
        s.name = null
        s.size = null
      })
    }

    if (logo && logo.source) {
      const form = new FormData();
      const file = yield call(URLToFormData, [logo.source]);
      form.append('file', file);
      newProduct.logo = yield call([ApiService, 'postLogo'], { form, slug });
    }

    const result = yield call([ApiService, 'updateProduct'], { product: newProduct });

    // Should the call fail, don't attempt to update the product in our store and cause the app to behave awkward (example: loses published status, loses premium status)
    if (result.status >= 400 && result.status <= 599) {
      throw result.data.error
    }

    if (action.product.newSlug) {
      yield delay(3000);
      yield call(productsRequest);
      yield put(push(`/products/${action.product.newSlug}/admin`));

    } else {
      yield put(actions.getProductSuccess(result));
    }

    toast.success("Saved!");

  } catch (err) {
    let errorMessage = "Error. Not saved."
    if (err?.retryable) {
      errorMessage += ` Please try again after ${moment.duration(err?.retryDelay || 180).humanize()}.`
    }

    toast.error(errorMessage);
    console.log(err);
  } finally {
    yield put(actions.completeUpdateProduct());
  }
}

export function* reviewsRequest(action) {
  scrollToTop();

  const product = action.payload.slug;
  const page = action.payload.page;
  const featured = action.payload.featured;
  const reviewPreference = action.payload.reviewPreference;
  const perPage = action.payload.perPage || 10;

  try {
    const result = yield call([ApiService, 'reviews'], {
      product,
      page,
      featured,
      reviewPreference,
      perPage,
    });
    yield put(actions.reviewsRequestSuccessAction(result));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.reviewsRequestCompleteAction());
  }
}

export function* leadsRequest(action) {
  scrollToTop();

  const slug = action.payload.slug;

  try {
    const result = yield call([ApiService, 'leads'], {
      slug
    });
    yield put(actions.leadsRequestSuccessAction(result));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.leadsRequestCompleteAction());
  }
}

export function* invitesRequest(action) {
  scrollToTop();
  const { page = 1, perPage = 100, slug } = action.payload;

  try {
    const result = yield call([ApiService, 'getInvites'], {
      slug,
      page,
      perPage,
    });
    yield put(actions.invitesRequestSuccessAction(result));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.invitesRequestCompleteAction());
  }
}

export function* invitesUpload(action) {
  scrollToTop();
  try {
    const product = yield select(selectProduct);
    const result = yield call([ApiService, 'createInvites'], {
      slug: product.slug,
      invites: action.payload.invites,
      sendDate: action.payload.sendDate,
    });
    yield put(actions.invitesUploadSuccessAction(result));
    toast.success("Success!");

    if (result.length > 0) {
      yield put(push(`/products/${product.slug}/reviews/invitations`));
    } else {
      const productInvites = product.invites || {};
      yield put(actions.getProductSuccess({
        ...product,
        invites: {
          ...productInvites,
          sentTest: true,
        },
      }));
    }

  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.invitesUploadCompleteAction());
  }
}

export function* reviewReply(action) {
  try {
    const result = yield call([ApiService, 'replyToReview'], action.payload);
    yield put(actions.reviewReplySuccessAction({
      ...action.payload.review,
      reply: action.payload.reply,
    }));
    toast.success("Success!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.reviewReplyCompleteAction());
  }
}

export function* reviewDelete(action) {
  try {
    const result = yield call([ApiService, 'deleteReview'], action.payload.id);
    yield put(actions.reviewDeleteSuccessAction(result));
    toast.success("Success!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.reviewDeleteCompleteAction());
  }
}

export function* leadReply(action) {
  try {
    const result = yield call([ApiService, 'replyToLead'], action.payload);
    yield put(actions.leadReplySuccessAction(result));
    toast.success("Success!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.leadReplyCompleteAction());
  }
}

export function* setLeadStatus(action) {
  try {
    const lead = yield call([ApiService, 'setLeadStatus'], action.lead.TYPE, action.status);
    yield put(actions.setLeadStatusSuccessAction(lead));
    toast.success("Moved");
  } catch (err) {
    toast.error(err?.description || "Error");
  } finally {
    yield put(actions.setLeadStatusCompleteAction());
  }
}

export function* createPaymentIntent(action) {
  if (action.scrollToTop) scrollToTop();

  try {
    if (!action.amount) {
      yield put(actions.createPaymentIntentSuccessAction());
      yield put(actions.createPaymentIntentCompleteAction());

    } else {
      const { slug } = yield select(selectProduct);
      const amount = Math.round((action.amount * 100 + Number.EPSILON) * 100) / 100; // Convert £ to p
      const paymentIntent = yield call([ApiService, 'createPaymentIntent'], { slug, amount, testMode: STRIPE_TEST_MODE || false });

      if (paymentIntent.client_secret) {
        yield put(actions.createPaymentIntentSuccessAction(paymentIntent));
      } else {
        toast.error(paymentIntent.data?.error?.description || 'Error');
      }
    }

  } catch (err) {
    console.log(err);
    toast.error(err.description || "Error");

  } finally {
    yield put(actions.createPaymentIntentCompleteAction());
  }
}

export function* confirmPayment(action) {
  try {
    const { stripe, card, creditAmount, leadsBought = null, clientSecret, supabaseUserId } = action.payload;
    // Cast string to number.
    const amount = Number(creditAmount);

    const { slug, leadCreditBalance, pricePerLead, paidLeadCount } = yield select(selectProduct);
    const isLeadBundleUser = !!pricePerLead && pricePerLead > 0
    const result = yield call([stripe, 'confirmCardPayment'], clientSecret, {
      payment_method: { card }
    });

    if (result.paymentIntent?.status === 'succeeded') {
      const transaction = yield call([ApiService, 'newLeadCreditsTransaction'], {
        slug,
        amount: amount,
        tax: currency(amount, { precision: 2 }).multiply(VAT_RATE).value,
        leadCredits: leadsBought,
        stripeTransactionId: result.paymentIntent.id,
        note: 'Top-up',
        supabaseUserId
      })
      toast.success("Success!");
      // This just updates the lead credit balance for the product, really.
      // We don't need to update history locally, here, since the history is always fetched fresh when you open that tab.
      yield put(actions.confirmPaymentSuccessAction({
        newMonetaryBalance: !isLeadBundleUser ? leadCreditBalance + amount : leadCreditBalance,
        newPaidLeadCount: isLeadBundleUser ? paidLeadCount + leadsBought : paidLeadCount,
        paymentIntent: result.paymentIntent
      }));

    } else {
      console.log(result.error.message);
      yield put(actions.confirmPaymentErrorAction(result.error.message));
    }

  } catch (err) {
    console.log(err);
    toast.error("Error");

  } finally {
    yield put(actions.confirmPaymentCompleteAction());
  }
}

export function* createSubscription(action) {
  scrollToTop();

  if (!action.payload) {
    yield put(actions.createSubscriptionSuccessAction());
    yield put(actions.createSubscriptionCompleteAction());
    yield put(storePreselectedSubscriptionAction(null));

  } else {
    try {
      const { slug } = yield select(selectProduct);
      const subscription = yield call([ApiService, 'createSubscription'], { slug, ...action.payload });

      if (subscription.subscriptionId) {
        yield put(actions.createSubscriptionSuccessAction({
          slug,
          ...subscription,
        }));
      } else {
        toast.error(subscription.data?.error?.description || 'Error');
      }

    } catch (err) {
      console.log(err);
      toast.error(err.description || "Error");

    } finally {
      yield put(actions.createSubscriptionCompleteAction());
    }
  }
}

export function* applyCoupon(action) {
  try {
    scrollToTop();
    const { code } = action.payload;
    const coupon = yield call([ApiService, 'getCoupon'], code);

    if (coupon?.valid) {
      toast.success("Success!");
      yield put(actions.applyCouponSuccessAction(coupon));
    } else {
      toast.error("Code not valid");
    }

  } catch (err) {
    console.log(err);
    toast.error(err.message || "Error");

  } finally {
    yield put(actions.applyCouponCompleteAction());
  }
}

export default function* watch() {
  yield takeLatest(actionTypes.PRODUCTS_REQUEST, productsRequest);
  yield takeLatest(actionTypes.CONFIG_REQUEST, configRequest);
  yield takeLatest(actionTypes.CREATE_PRODUCT, createProduct);
  yield takeLatest(actionTypes.UPDATE_PRODUCT, updateProduct);
  yield takeLatest(actionTypes.GET_PRODUCT, getProductSaga);
  yield takeLatest(actionTypes.EXPORT_REVIEWS, exportReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_REVIEWS, exportAllReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_LEADS, exportAllLeads);
  yield takeLatest(actionTypes.EXPORT_ALL_ANON_USERS, exportAllAnonUsers);
  yield takeLatest(actionTypes.EXPORT_ALL_PRODUCTS, exportAllProducts);
  yield takeLatest(actionTypes.REVIEWS_REQUEST, reviewsRequest);
  yield takeLatest(actionTypes.LEADS_REQUEST, leadsRequest);
  yield takeLatest(actionTypes.INVITES_REQUEST, invitesRequest);
  yield takeLatest(actionTypes.INVITES_UPLOAD, invitesUpload);
  yield takeLatest(actionTypes.UPLOAD_EVIDENCE, uploadEvidence);
  yield takeLatest(actionTypes.DELETE_EVIDENCE, deleteEvidence);
  yield takeLatest(actionTypes.UPDATE_CONFIG, updateConfig);
  yield takeLatest(actionTypes.WP_CATEGORY_DATA, getWordPressCategoryData);
  yield takeLatest(actionTypes.REVIEW_REPLY, reviewReply);
  yield takeLatest(actionTypes.REVIEW_DELETE, reviewDelete);
  yield takeLatest(actionTypes.LEAD_REPLY, leadReply);
  yield takeLatest(actionTypes.SET_LEAD_STATUS, setLeadStatus);
  yield takeLatest(actionTypes.DELETE_LEAD, deleteLead);
  yield takeLatest(actionTypes.PAYMENT_INTENT, createPaymentIntent);
  yield takeLatest(actionTypes.CONFIRM_PAYMENT, confirmPayment);
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION, createSubscription);
  yield takeLatest(actionTypes.APPLY_COUPON, applyCoupon);
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_SUCCESS, getProductSaga);
}
