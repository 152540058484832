import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
// Custom Scrollbar
import moment from 'moment';
import Loader from "../../components/Loader";
import Rating from "../../components/Rating";
// import images
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";

import impactEvidence from '../../assets/images/impact-evidence.jpg';
import engagement from '../../assets/images/engagement.jpg';
import ethical from '../../assets/images/ethical.jpg';

import avatar from "../../assets/images/avatar.png";

import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Card as MuiCard, CardActions, CardContent, CardMedia, Button, Typography, Stack } from '@mui/material';
import { FormatQuoteOutlined } from "@mui/icons-material";

import { useProductFruitsApi } from 'react-product-fruits';

const Dashboard = ({ product: p, device, reseller, reviews, reviewsPending, isPremium }) => {
  
  // useProductFruitsApi(api => {
  //   if (!reviewsPending) {
  //     console.log(api)
  //     api.tours.tryStartTour(44518);
  //   }
  // }, [reviewsPending]);

  if (reviewsPending) return <Loader />;

  const product = p || device || reseller;
  const baseLink = product && `/products/${product?.slug}`;

  return (
    <StyledDashboard>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col sm={6}>
            <div className="page-title-box">
              <h4 className="font-size-18">Dashboard</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item active">
                  Welcome to your EdTech Impact Dashboard
                </li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={4} md={6} sm={6}>
            <HealthCheckCard
              title="How strong is your Impact Evidence?"
              description="Answer 18 questions to receive a personalised report with feedback on the strength of your impact evidence portfolio. "
              image={impactEvidence}
              href={`${baseLink}/health-check`}
              minutes={5}
            />
          </Col>

          <Col xl={4} md={6} sm={6}>
            <HealthCheckCard
              title={`How ethical, lawful and safe is ${product.name}?`}
              description="Answer 28 questions to receive a personalised report with feedback on how to provide an ethically responsible and fit for purpose product for schools."
              image={ethical}
              href={`${baseLink}/health-check/compliance-safety`}
              minutes={8}
            />
          </Col>

          <Col xl={4} md={6} sm={6}>
            <HealthCheckCard
              title="Do you provide a high-quality learning engagement?"
              description={`Answer 28 questions to evaluate the level of learning engagement quality of ${product.name}.`}
              image={engagement}
              href={`${baseLink}/health-check/learning-engagement`}
              minutes={8}
            />
          </Col>

          <StatCards
            product={product}
            isPremium={isPremium}
          />

          {/* <Col xl={3} md={6}>
            <Card className="mini-stat bg-primary text-white">
              <CardBody>
                <div className="mb-4">
                  <div className="float-left mini-stat-img mr-4">
                    <img src={servicesIcon1} alt="" />
                  </div>
                  <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                    Leads
                  </h5>
                  <h4 className="font-weight-medium font-size-24">
                    {product.messageLeads ?
                    product.messageLeads
                    : 0}
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        
        <Row>
              {reviews && reviews.data && reviews.data.slice(0,4).map(review => {
              
              let daysAgo = moment().diff(moment(review.date), 'days');
              daysAgo = daysAgo === 0 ? 'Today' : daysAgo === 1 ? 'Yesterday' : `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;

              return (
                <Col md={6} key={review.TYPE}>
                  <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Review: {daysAgo}</h4>
                    <Rating
                      style={{ marginBottom: '1rem', width: '7rem', height: '1.3rem' }}
                      value={review.score / 2}
                      editing={false}
                      name={review.name}
                    />
                      {review.scoreExplanation &&
                        <Stack direction="row" gap={1}>
                          <FormatQuoteOutlined sx={{ transform: "scaleX(-1)" }} fontSize="small" />
                          <Typography variant="body2">{review.scoreExplanation}</Typography>
                        </Stack>
                      }
                    {/* <div className="float-right mt-2">
                      <Link to="#" className="text-primary">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div> */}
                    <h6 className="mb-0">
                      {" "}
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-sm rounded-circle mr-2"
                      />{" "}
                      {review.name}{review.schoolName && review.schoolName !== "undefined" ? `, ${review.schoolName}` : null}
                    </h6>
                  </CardBody>
                  </Card>
                </Col>
              )})}
        </Row>

        </div>
    </StyledDashboard>
  );
}

const StyledDashboard = styled.div`
  .card {
    min-height: 133.5px;
  }

  .mini-stat {
    height: 285px;
  }
`;

const mapStateToProps = state => {
  const { product, reviews, reviewsPending} = state.Product;
  const { device } = state.Device;
  const { reseller } = state.Reseller;
  return { product, device, reseller, reviews, reviewsPending };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(Dashboard);



const HealthCheckCard = ({image, title, description, href, minutes}) => {
  return (
    <MuiCard sx={{mb: 4}}>
      <CardMedia
        sx={{ height: 140 }}
        image={image}
        title={title}
      />
      <CardContent sx={{minHeight: 240}}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Typography variant="body2">
          <strong>It's free and takes just {minutes} minutes.</strong>
        </Typography>
      </CardContent>
      <CardActions>
        <Link to={href}>
          <Button variant="contained" color="primary">Take the Health Check</Button>
        </Link>
      </CardActions>
    </MuiCard>
  );
}

export const StatCards = ({ product, isPremium }) => {
  const baseLink = product && `/products/${product?.slug}`;
  let lastReviewDaysAgo = moment().diff(moment(product.mostRecentReviewDate), 'days');
  lastReviewDaysAgo = lastReviewDaysAgo === 0 ? 'Today' : lastReviewDaysAgo === 1 ? 'Yesterday' : <>{lastReviewDaysAgo} <sub>{lastReviewDaysAgo === 1 ? 'day' : 'days'} ago</sub></>;

  return (
    <>
      <Col xl={4} md={6} sm={6}>
            <Card className="mini-stat bg-primary text-white">
              <CardBody>
                <div className="mb-4 d-flex">
                  <div className="float-left mini-stat-img mr-4">
                    <img src={servicesIcon2} alt="" />
                  </div>
                  <div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      User Rating
                    </h5>
                    <h4 className="font-weight-medium font-size-24 mb-4">
                      {product.userRating ? <>{Math.round(product.userRating * 5) / 10}<sub>/5</sub></> : '-'}
                    </h4>
                    {isPremium &&
                    <>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        NPS
                      </h5>
                      <h4 className="font-weight-medium font-size-24">
                        {product.NPS ? <>{product.NPS}<sub>/100</sub></> : '-'}
                      </h4>
                    </>}
                  </div>
                </div>
              </CardBody>
            </Card>
      </Col>

      <Col xl={4} md={6} sm={6}>
        <Card className="mini-stat bg-primary text-white">
          <CardBody>
            <div className="mb-2 d-flex">
              <div className="float-left mini-stat-img mr-4">
                <img src={servicesIcon3} alt="" />
              </div>
              <div>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Reviews
                </h5>
                <h4 className="font-weight-medium font-size-24 mb-4">
                  {product.numOfReviews}{" "}
                </h4>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Last 6 months
                </h5>
                <h4 className="font-weight-medium font-size-24 mb-4">
                  {product.sixMonthReviewCount || 0}{" "}
                </h4>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                Most recent
              </h5>
              <h4 className="font-weight-medium font-size-24">
                {product.mostRecentReviewDate ?
                lastReviewDaysAgo
                : '-'}
              </h4>
              </div>
            </div>
              <div>
                <div className="float-right">
                  <Link to={`${baseLink}/reviews/your-reviews`} className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>
              </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={4} md={6} sm={6}>
        <Card className="mini-stat bg-primary text-white">
          <CardBody>
            <div className="mb-2 d-flex">
              <div className="float-left mini-stat-img mr-4">
                <img src={servicesIcon4} alt="" />
              </div>
              <div>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  Invites sent
                </h5>
                <h4 className="font-weight-medium font-size-24 mb-4">
                  {product.invites?.sent ?
                    product.invites.sent
                  : 0}
                </h4>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                  This month
                </h5>
                <h4 className="font-weight-medium font-size-24 mb-4">
                  { product.invites?.sentThisMonth ?
                    product.invites.sentThisMonth
                  : 0}
                </h4>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                Queued
              </h5>
              <h4 className="font-weight-medium font-size-24">
                {product.invites?.queued ?
                  product.invites.queued
                : 0}
              </h4>
              </div>
            </div>
              <div>
                <div className="float-right">
                  <Link to={`${baseLink}/reviews/invitations`} className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>
              </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}