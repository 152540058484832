import Immutable from 'seamless-immutable';
import moment from 'moment'

import * as actionTypes from './constants';
import * as billingActionTypes from './BillingPage/constants';
import * as adminActionTypes from '../SiteAdmin/constants';

export const initialState = Immutable({
  products: null,
  productsPending: false,
  product: null,
  productPending: false,
  createPending: false,
  wordpress: {
    categories: null,
  },
  paymentIntent: null,
  paymentIntentPending: false,
  paymentPending: false,
  adjustLeadCreditsPending: false,
  leadCreditsHistoryPending: true,
  leadCreditsTransactions: null,
});

function productReducer(state = initialState, action) {
  const emptyProduct = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};
  let leadIndex;

  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.CONFIG_REQUEST_SUCCESS:
      return state
        .set('config', action.payload.config)
        .set('planConfig', action.payload.planConfig);

    case actionTypes.UPDATE_CONFIG:
      return state.set('updatePending', true);
    case actionTypes.UPDATE_CONFIG_SUCCESS:
      return state.set('config', action.payload);

    case actionTypes.UPDATE_CONFIG_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.WP_CATEGORY_DATA:
      return state.set('updatePending', true);
    case actionTypes.WP_CATEGORY_DATA_SUCCESS:
      return state.setIn(['wordpress', 'categories'], action.payload);
    case actionTypes.WP_CATEGORY_DATA_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.UPDATE_PRODUCT:
      return state.set('updatePending', true);
    case actionTypes.COMPLETE_UPDATE_PRODUCT:
      return state.set('updatePending', false);

    case actionTypes.UPLOAD_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.DELETE_EVIDENCE:
      return state.set('updatePending', true);

    case actionTypes.PRODUCTS_REQUEST:
      return state.set('productsPending', true);
    case actionTypes.PRODUCTS_REQUEST_SUCCESS:
      if (state.products && state.products.length > 1) {
        return state.set('products', action.payload);
      } else {
        return state.set('products', [...action.payload, emptyProduct]);
      }

    case actionTypes.PRODUCTS_REQUEST_COMPLETE:
      return state.set('productsPending', false);

    case actionTypes.CREATE_PRODUCT:
      return state.set('createPending', true);
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return state.set('products', state.products ? [...state.products.slice(0, state.products.length-1), action.product, emptyProduct] : [action.product, emptyProduct]);
    case actionTypes.CREATE_PRODUCT_COMPLETE:
      return state.set('createPending', false);
    
    case actionTypes.EXPORT_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_REVIEWS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_ALL_PRODUCTS:
      return state.set('updatePending', true);
    case actionTypes.EXPORT_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.INVITES_REQUEST:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_REQUEST_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_REQUEST_COMPLETE:
      return state.set('invitesPending', false);

    case actionTypes.INVITES_UPLOAD:
      return state.set('invitesPending', true);
    case actionTypes.INVITES_UPLOAD_SUCCESS:
      return state.set('invites', action.payload);
    case actionTypes.INVITES_UPLOAD_COMPLETE:
      return state.set('invitesPending', false);
    
    case actionTypes.REVIEWS_REQUEST:
      return state.set('reviewsPending', true);
    case actionTypes.REVIEWS_REQUEST_SUCCESS:
      return state.set('reviews', action.payload);
    case actionTypes.REVIEWS_REQUEST_COMPLETE:
      return state.set('reviewsPending', false);

    case actionTypes.LEADS_REQUEST:
      return state.set('leadsPending', true);
    case actionTypes.LEADS_REQUEST_SUCCESS:
      return state.set('leads', action.payload);
    case actionTypes.LEADS_REQUEST_COMPLETE:
      return state.set('leadsPending', false);

    case actionTypes.REVIEW_REPLY:
      return state.set('updatePending', true);
    case actionTypes.REVIEW_REPLY_SUCCESS:
      const reviewIndex = state.reviews.data.findIndex(r => r.TYPE === action.payload.TYPE);
      return state.setIn(['reviews', 'data', reviewIndex], action.payload);
    case actionTypes.REVIEW_REPLY_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.REVIEW_DELETE:
      return state.set('updatePending', true);
    case actionTypes.REVIEW_DELETE_SUCCESS:
      const index = state.reviews.data.findIndex(review => review.TYPE === action.payload.TYPE);
      if (index > -1) {
        const data = [...state.reviews.data.slice(0,index), ...state.reviews.data.slice(index+1)];
        return state.setIn(['reviews', 'data'], data);
      }
    case actionTypes.REVIEW_DELETE_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.LEAD_REPLY:
      return state.set('updatePending', true);
    case actionTypes.LEAD_REPLY_SUCCESS:
      leadIndex = state.leads?.findIndex(l => l.TYPE === action.payload.TYPE);
      if (leadIndex !== false) return state.setIn(['leads', leadIndex], action.payload);
      return state;
    case actionTypes.LEAD_REPLY_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.SET_LEAD_STATUS:
      return state.set('updatePending', true);
    case actionTypes.SET_LEAD_STATUS_SUCCESS:
      leadIndex = state.leads?.findIndex(l => l.TYPE === action.payload.TYPE);
      if (leadIndex !== false) return state.setIn(['leads', leadIndex], action.payload);
      return state;
    case actionTypes.SET_LEAD_STATUS_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.GET_PRODUCT:
      return state.set('productPending', true);
    case actionTypes.GET_PRODUCT_SUCCESS:
      return state.set('product', action.product);
    case actionTypes.GET_PRODUCT_COMPLETE:
      return state.set('productPending', false);

    case actionTypes.DELETE_LEAD:
      return state.set('updatePending', true);
    case actionTypes.DELETE_LEAD_SUCCESS:
      return state.set('leads', state.leads.filter(l => l.TYPE !== action.lead.TYPE));
    case actionTypes.DELETE_LEAD_COMPLETE:
      return state.set('updatePending', false);

    case actionTypes.PAYMENT_INTENT:
      return state
        .set('paymentIntentPending', true);
    case actionTypes.PAYMENT_INTENT_SUCCESS:
      return state.set('paymentIntent', action.paymentIntent);
    case actionTypes.PAYMENT_INTENT_COMPLETE:
      return state
        .set('paymentIntentPending', false)
        .set('paymentError', null);

    case actionTypes.CONFIRM_PAYMENT:
      return state.set('paymentPending', true);
      case actionTypes.CONFIRM_PAYMENT_SUCCESS:
        return state
        .set('paymentIntent', action.paymentIntent)
        .set('paymentError', null)
        .setIn(['product', 'leadCreditBalance'], action.newMonetaryBalance)
        .setIn(['product', 'paidLeadCount'], action.newPaidLeadCount);
    case actionTypes.CONFIRM_PAYMENT_ERROR:
      return state.set('paymentError', action.error);
    case actionTypes.CONFIRM_PAYMENT_COMPLETE:
      return state.set('paymentPending', false);

    case actionTypes.DELETE_SUBSCRIPTION:
      return state.set('subscription', null);

    case actionTypes.CREATE_SUBSCRIPTION:
      return state
        .set('subscriptionPending', true);
    case actionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return state
        .set('subscription', action.subscription)
        .setIn(['product', 'subscription'], action.subscription);
    case actionTypes.CREATE_SUBSCRIPTION_COMPLETE:
      return state
        .set('subscriptionPending', false)
        .set('paymentError', null);

    case actionTypes.APPLY_COUPON:
      return state.set('couponPending', true);
    case actionTypes.APPLY_COUPON_SUCCESS:
      return state.set('coupon', action.payload);
    case actionTypes.APPLY_COUPON_COMPLETE:
      return state.set('couponPending', false);

    // case actionTypes.CONFIRM_PAYMENT:
    //   return state.set('paymentPending', true);
    //   case actionTypes.CONFIRM_PAYMENT_SUCCESS:
    //     return state
    //     .set('paymentIntent', action.paymentIntent)
    //     .set('paymentError', null)
    //     .set('product', action.product);
    // case actionTypes.CONFIRM_PAYMENT_ERROR:
    //   return state.set('paymentError', action.error);
    // case actionTypes.CONFIRM_PAYMENT_COMPLETE:
    //   return state.set('paymentPending', false);

    case adminActionTypes.GET_PRODUCT_ADMIN_STATS:
      return state.set('updatePending', true);
    case adminActionTypes.GET_PRODUCT_ADMIN_STATS_SUCCESS:
      return state.set('adminStats', state.adminStats ? {...state.adminStats, stats: action.payload } : {stats: action.payload} );
    case adminActionTypes.GET_PRODUCT_ADMIN_STATS_COMPLETE:
      return state.set('updatePending', false);

    case adminActionTypes.GET_PRODUCT_VISITOR_STATS:
      return state.set('updatePending', true);
    case adminActionTypes.GET_PRODUCT_VISITOR_STATS_SUCCESS:
      return state.set('adminStats', state.adminStats ? {...state.adminStats, events: action.payload } : {events: action.payload} );
    case adminActionTypes.GET_PRODUCT_VISITOR_STATS_COMPLETE:
      return state.set('updatePending', false);

    case billingActionTypes.LEAD_CREDITS_ADJUST:
      return state.set('adjustLeadCreditsPending', true)
    case billingActionTypes.LEAD_CREDITS_ADJUST_SUCCESS:
      // We don't really need to add the transaction to the history. If the user switches to the history tab after adjusting the balance, it's going to re-fetch data anyway. For now, we can skip this. If this feature becomes public in the future (and not admin-only), we can look at optimising this whole flow to make fewer API calls.
      // However, we do need to update the product balance - that can be monetary as well as number of lead credits.
      return state
        .setIn(['product', 'leadCreditBalance'], state.product.leadCreditBalance + action.payload.amount)
        .setIn(['product', 'freeLeadCount'], (state.product.freeLeadCount || 0) + (action.payload.is_free ? action.payload.lead_credits : 0))
        .setIn(['product', 'paidLeadCount'], (state.product.paidLeadCount || 0) + (action.payload.is_free ? 0 : action.payload.lead_credits))
    case billingActionTypes.LEAD_CREDITS_ADJUST_COMPLETE:
      return state.set('adjustLeadCreditsPending', false)

    case billingActionTypes.LEAD_CREDITS_HISTORY:
      return state.set('leadCreditsHistoryPending', true)
    case billingActionTypes.LEAD_CREDITS_HISTORY_SUCCESS:
      if (Array.isArray(action.payload)) {
        if (action.payload.length) {
          const transactions = [...action.payload].sort((t1, t2) => moment(t2.created_at) - moment(t1.created_at))
          
          return state.set('leadCreditsTransactions', transactions)
        }

        return state.set('leadCreditsTransactions', [])
      }
      return state
    case billingActionTypes.LEAD_CREDITS_HISTORY_COMPLETE:
      return state.set('leadCreditsHistoryPending', false)
   
    default:
      return state;
  }
}

export default productReducer;
