import 'whatwg-fetch';
// import VError from 'verror';
import { API_V2_URL, API_V2_DEV_URL, IS_PRODUCTION, WORDPRESS_API_URL } from '../../config/settings';
import { API as AMPLIFY_API } from 'aws-amplify';

const EFFECTIVE_API_NAME = IS_PRODUCTION ? 'ei-api' : 'ei-api-dev'
const EFFECTIVE_API_URL = IS_PRODUCTION ? API_V2_URL : API_V2_DEV_URL

export class ApiService {
  constructor(fetch) {
    this.fetch = fetch;
    this.version = 1;
  }

  authorisedRequest(method, endpoint, payload) {
    return AMPLIFY_API[method](EFFECTIVE_API_NAME, endpoint, payload)
    .then(response => response)
    .catch(error => {
      console.log(error.response);
      return error.response;
    });
  }

  request(
    api = EFFECTIVE_API_URL,
    endpoint,
    method,
    payload,
    headers,
    credentials,
    transformer,
  ) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'x-accel-token': credentials ? credentials.token : undefined,
      'x-accel-user': credentials ? credentials.user : undefined,
    };
    Object.keys(defaultHeaders).forEach(
      key =>
        defaultHeaders[key] === undefined ? delete defaultHeaders[key] : '',
    );
    const options = {
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      body: null,
      method,
    };
    if (method !== 'GET' && payload) {
      options.body =
        typeof payload === 'string' ? payload : JSON.stringify(payload);
    }
    const defaultTransformer = async response => {
      if (response.status === 204) {
        return {};
      }
      return response.json().then(responseJson => responseJson);
    };
    return this.fetch
      .call(window, `${api}${endpoint}`, options)
      .then(
        response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          }
          // throw new VError({ info: { cause: response } }, `${response.status}`);
        },
        error => {},
      )
      .then(
        response =>
          transformer ? transformer(response) : defaultTransformer(response),
      )
      .catch(error => {
        console.log(error);
      })
  }

  get(api, endpoint, headers, credentials, transformer) {
    return this.request(
      api,
      endpoint,
      'GET',
      undefined,
      headers,
      credentials,
      transformer,
    );
  }

  post(api, endpoint, payload, headers, credentials, transformer) {
    return this.request(
      api,
      endpoint,
      'POST',
      payload,
      headers,
      credentials,
      transformer,
    );
  }

  put(api, endpoint, payload, headers, credentials, transformer) {
    return this.request(
      api,
      endpoint,
      'PUT',
      payload,
      headers,
      credentials,
      transformer,
    );
  }

  delete(api, endpoint, headers, credentials, transformer) {
    return this.request(
      api,
      endpoint,
      'DELETE',
      undefined,
      headers,
      credentials,
      transformer,
    );
  }

  tokenResponseTransformer(response) {
    return response.json().then(responseJson => {
      // Catch legacy API response where checkProgress URL for token is
      // returned as whole response body, in plain text
      if (typeof responseJson === 'string') {
        return responseJson.substr(responseJson.lastIndexOf('/') + 1);
      }
      return responseJson.result.token;
    });
  }

  product(slug) {
    return this.get(EFFECTIVE_API_URL, `/products/${slug}`);
  }

  getInvites({slug, page, perPage}) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/products/${slug}/invites`);
  }

  createInvites({slug, invites, sendDate}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/invites`, {
      body: { invites, sendDate }
    });
  }

  createItem(item, type) {
    return this.authorisedRequest('post', `/${type}s`, {
      body: { [type]: item },
    });
  }

  updateItem(item, type) {
    return this.authorisedRequest('put', `/${type}s/${item.slug}`, {
      body: { [type]: item },
    });
  }

  getUserItems(type) {
    return this.authorisedRequest('get', `/user/${type}s`);
  }

  getUserItem(slug, type) {
    return this.authorisedRequest('get', `/user/${type}s/${slug}`);
  }

  createProduct(product) {
    return this.authorisedRequest('post', `/products`, {
      body: { product },
    });
  }

  updateProduct({ product }) {
    return this.authorisedRequest('put', `/products/${product.slug}`, {
      body: { product },
    });
  }

  getUserProduct(slug) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/user-products/${slug}`);
  }

  getProductAdminStats(slug) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/products/${slug}/admin-stats`);
  }

  getConfig() {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/config`);
  }

  getPlanConfig() {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/config/plans`);
  }

  updateConfig(config) {
    return AMPLIFY_API.put(EFFECTIVE_API_NAME, `/config`, {
      body: config,
    });
  }

  postImage({form}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/images`, {
      body: form,
    });
  }

  postLogo({form, slug}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/logo`, {
      body: form,
    });
  }

  postScreenshots({form, slug}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/screenshots`, {
      body: form,
    });
  }

  postEvidence({files, slug}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/evidence`, {
      body: { files },
    });
  }

  deleteEvidence({id, slug}) {
    return AMPLIFY_API.del(EFFECTIVE_API_NAME, `/products/${slug}/evidence/${id}`);
  }

  getUnapprovedLeads({ page, perPage }) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/leads?filter=unapproved&page=${page}&perPage=${perPage}`);
  }

  approveLead(lead, withMessage, leadValue, numberOfCredits, alternatives) {
    return AMPLIFY_API.put(EFFECTIVE_API_NAME, `/leads/${lead.TYPE}/approve`, {
      body: { withMessage, leadValue, lead, numberOfCredits, alternatives},
    });
  }

  /**
   * 
   * @param {string} TYPE lead TYPE for DDB
   * @param {string} status new lead status
   * @param {int|null} rating new lead rating
   * @todo Rename to updateLead
   * @returns 
   */
  setLeadStatus(TYPE, status, rating = null) {
    return AMPLIFY_API.put(EFFECTIVE_API_NAME, `/leads/${TYPE}`, {
      body: { status, rating },
    });
  }

  deleteLead({TYPE, rejection}) {
    const queryString = rejection ? `?rejection=true` : '';
    return AMPLIFY_API.del(EFFECTIVE_API_NAME, `/leads/${TYPE}${queryString}`);
  }

  leads({ slug }) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/products/${slug}/leads`);
  }

  replyToLead({id, reply}) {
    return AMPLIFY_API.put(EFFECTIVE_API_NAME, `/leads/${id}/reply`, {
      body: {reply},
    });
  }

  newLeadCreditsTransaction({ slug, amount, tax, leadCredits, note, stripeTransactionId, supabaseUserId, ddbLeadType = null, sendEmail = false, isFree = false }) {
    return this.authorisedRequest('post', `/products/${slug}/transactions`, {
      body: {
        type: 'LEAD_CREDITS',
        amount: amount || null,
        tax: tax || null,
        leadCredits: leadCredits || null,
        note,
        stripeTransactionId: stripeTransactionId || null,
        supabaseUserId,
        ddbLeadType,
        sendEmail,
        isFree,
      }
    });
  }

  transactions({
    slug,
    type = null,
    withApprovedLeads = false,
    limit = false
  }) {
    const params = new URLSearchParams()
    if (type) params.append('type', type)
    if (withApprovedLeads) params.append('with_approved_leads', withApprovedLeads)
    if (limit) params.append('limit', limit)

    let endpoint = `/products/${slug}/transactions`;
    if (params.toString() !== '')
      endpoint += `?${params.toString()}`

    return this.authorisedRequest('get', endpoint);
  }

  createPaymentIntent({ slug, amount, testMode }) {
    return this.authorisedRequest('post', `/products/${slug}/payment-intents`, {
      body: {
        amount,
        testMode
      },
    });
  }

  createPayment(data) {
    return this.authorisedRequest('post', `/products/${data.slug}/payments`, {
      body: data,
    });
  }

  createSubscription({slug, ...body}) {
    return this.authorisedRequest('post', `/products/${slug}/subscriptions`, {
      body
    });
  }

  getCoupon(code) {
    return this.authorisedRequest('get', `/coupons/${code}`);
  }

  reviews({ page=1, perPage=5, filters={}, product, featured=false, reviewPreference=false }) {
    return this.get(
      EFFECTIVE_API_URL,
      `/reviews/${product}?page=${page}&perPage=${perPage}&reviewPreference=${reviewPreference}&featured=${featured}&filters=${encodeURIComponent(
        JSON.stringify(filters),
      )}`,
    );
  }

  reviewsExport({product}) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/reviews/${product}/export`);
  }

  replyToReview({id, reply}) {
    return AMPLIFY_API.put(EFFECTIVE_API_NAME, `/review/${id}/reply`, {
      body: {reply},
    });
  }

  deleteReview(id) {
    return AMPLIFY_API.del(EFFECTIVE_API_NAME, `/review/${id}`);
  }

  getAnalyticsEvents({slug, alternatives, page=1, from, to}) {
    const pageString = `page=${page}`;
    const fromString = from ? `&from=${from}` : '';
    const toString = to ? `&to=${to}` : '';
    const alternativesString = alternatives ? `&alternatives=${JSON.stringify(alternatives)}` : '';
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/analytics/events/${slug}?${pageString}${fromString}${toString}${alternativesString}`);
  }

  getBuyerIntent({slug, ...data}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/buyer-intent`, {
      body: data
    });
  }

  getVisitorStats({slug}) {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/products/${slug}/visitor-stats`);
  }

  createProspect({slug, data}) {
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${slug}/prospects`, {
      body: data,
    });
  }

  allReviewsExport() {
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/admin/export?itemType=reviews&deleted=true&unverified=true`);
  }

  allClicksExport({fromDate, toDate, format}={}) {
    const formatString = format ? `/${format}` : '';
    const fromString = fromDate ? `?fromDate=${fromDate}` : '';
    const toString = toDate ? `&toDate=${toDate}` : '';
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/clicks/export${formatString}${fromString}${toString}`);
  }

  exportAllAnonUsers({fromDate, toDate, format}={}) {
    const formatString = format ? `/${format}` : '';
    const fromString = fromDate ? `?fromDate=${fromDate}` : '';
    const toString = toDate ? `&toDate=${toDate}` : '';
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/analytics/users`);
  }

  allLeadsExport({fromDate, toDate}) {
    const fromString = fromDate ? `fromDate=${fromDate}` : '';
    const toString = toDate ? `&toDate=${toDate}` : '';
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/admin/export?itemType=leads&${fromString}${toString}`);
  }

  allProductsExport({fromDate, toDate, marketingEmails}) {
    const fromString = fromDate ? `fromDate=${fromDate}` : '';
    const toString = toDate ? `&toDate=${toDate}` : '';
    const marketingEmailsString = marketingEmails ? `marketingEmails=true` : '';
    return AMPLIFY_API.get(EFFECTIVE_API_NAME, `/admin/export?itemType=products&${fromString}${toString}${marketingEmailsString}`);
  }

  // Not in use.
  getReview({type64}) {
    return this.get(EFFECTIVE_API_URL, `/review/${type64}`);
  }

  // Not in use.
  likeReview({type64}) {
    return this.post(EFFECTIVE_API_URL, `/review/${type64}/like`);
  }

  /**
   * 
   * @param {{ productSlug: string, refresh: boolean }}
   * @returns 
   */
  generateApiKey({ productSlug, refresh = false }) {
    const params = new URLSearchParams()
    params.append("refresh", refresh)
    return AMPLIFY_API.post(EFFECTIVE_API_NAME, `/products/${productSlug}/api_key/generate?${params.toString()}`)
  }

  getWordPressCategoryData() {
    return this.get(WORDPRESS_API_URL, "/categories")
  }
}

export default new ApiService(fetch);
