export const UPDATE_CONFIG = 'app/ProductAdmin/UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'app/ProductAdmin/UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_COMPLETE = 'app/ProductAdmin/UPDATE_CONFIG_COMPLETE';

export const WP_CATEGORY_DATA = 'app/ProductAdmin/WP_CATEGORY_DATA';
export const WP_CATEGORY_DATA_SUCCESS = 'app/ProductAdmin/WP_CATEGORY_DATA_SUCCESS';
export const WP_CATEGORY_DATA_COMPLETE = 'app/ProductAdmin/WP_CATEGORY_DATA_COMPLETE';

export const PRODUCTS_REQUEST = 'app/ProductAdmin/PRODUCTS_REQUEST';
export const PRODUCTS_REQUEST_COMPLETE = 'app/ProductAdmin/PRODUCTS_REQUEST_COMPLETE';
export const PRODUCTS_REQUEST_SUCCESS = 'app/ProductAdmin/PRODUCTS_REQUEST_SUCCESS';

export const CONFIG_REQUEST = 'app/ProductAdmin/CONFIG_REQUEST';
export const CONFIG_REQUEST_COMPLETE = 'app/ProductAdmin/CONFIG_REQUEST_COMPLETE';
export const CONFIG_REQUEST_SUCCESS = 'app/ProductAdmin/CONFIG_REQUEST_SUCCESS';

export const CREATE_PRODUCT = 'app/ProductAdmin/CREATE_PRODUCT';
export const CREATE_PRODUCT_COMPLETE = 'app/ProductAdmin/CREATE_PRODUCT_COMPLETE';
export const CREATE_PRODUCT_SUCCESS = 'app/ProductAdmin/CREATE_PRODUCT_SUCCESS';

export const INVITES_REQUEST = 'app/ProductAdmin/INVITES_REQUEST';
export const INVITES_REQUEST_COMPLETE = 'app/ProductAdmin/INVITES_REQUEST_COMPLETE';
export const INVITES_REQUEST_SUCCESS = 'app/ProductAdmin/INVITES_REQUEST_SUCCESS';

export const INVITES_UPLOAD = 'app/ProductAdmin/INVITES_UPLOAD';
export const INVITES_UPLOAD_COMPLETE = 'app/ProductAdmin/INVITES_UPLOAD_COMPLETE';
export const INVITES_UPLOAD_SUCCESS = 'app/ProductAdmin/INVITES_UPLOAD_SUCCESS';

export const REVIEWS_REQUEST = 'app/ProductAdmin/REVIEWS_REQUEST';
export const REVIEWS_REQUEST_COMPLETE = 'app/ProductAdmin/REVIEWS_REQUEST_COMPLETE';
export const REVIEWS_REQUEST_SUCCESS = 'app/ProductAdmin/REVIEWS_REQUEST_SUCCESS';

export const LEADS_REQUEST = 'app/ProductAdmin/LEADS_REQUEST';
export const LEADS_REQUEST_COMPLETE = 'app/ProductAdmin/LEADS_REQUEST_COMPLETE';
export const LEADS_REQUEST_SUCCESS = 'app/ProductAdmin/LEADS_REQUEST_SUCCESS';

export const LEAD_REPLY = 'app/ProductAdmin/LEAD_REPLY';
export const LEAD_REPLY_COMPLETE = 'app/ProductAdmin/LEAD_REPLY_COMPLETE';
export const LEAD_REPLY_SUCCESS = 'app/ProductAdmin/LEAD_REPLY_SUCCESS';

export const SET_LEAD_STATUS = 'app/ProductAdmin/SET_LEAD_STATUS';
export const SET_LEAD_STATUS_SUCCESS = 'app/ProductAdmin/SET_LEAD_STATUS_SUCCESS';
export const SET_LEAD_STATUS_COMPLETE = 'app/ProductAdmin/SET_LEAD_STATUS_COMPLETE';

export const DELETE_LEAD = 'app/ProductAdmin/DELETE_LEAD';
export const DELETE_LEAD_SUCCESS = 'app/ProductAdmin/DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_COMPLETE = 'app/ProductAdmin/DELETE_LEAD_COMPLETE';

export const REVIEW_REPLY = 'app/ProductAdmin/REVIEW_REPLY';
export const REVIEW_REPLY_COMPLETE = 'app/ProductAdmin/REVIEW_REPLY_COMPLETE';
export const REVIEW_REPLY_SUCCESS = 'app/ProductAdmin/REVIEW_REPLY_SUCCESS';

export const REVIEW_DELETE = 'app/ProductAdmin/REVIEW_DELETE';
export const REVIEW_DELETE_COMPLETE = 'app/ProductAdmin/REVIEW_DELETE_COMPLETE';
export const REVIEW_DELETE_SUCCESS = 'app/ProductAdmin/REVIEW_DELETE_SUCCESS';

export const EVIDENCE_REQUEST = 'app/ProductAdmin/EVIDENCE_REQUEST';
export const EVIDENCE_REQUEST_COMPLETE =
  'app/ProductAdmin/EVIDENCE_REQUEST_COMPLETE';
export const EVIDENCE_REQUEST_SUCCESS = 'app/ProductAdmin/EVIDENCE_REQUEST_SUCCESS';

export const EVIDENCE_TABLE_REQUEST = 'app/ProductAdmin/EVIDENCE_TABLE_REQUEST';
export const EVIDENCE_TABLE_REQUEST_COMPLETE =
  'app/ProductAdmin/EVIDENCE_TABLE_REQUEST_COMPLETE';
export const EVIDENCE_TABLE_REQUEST_SUCCESS =
  'app/ProductAdmin/EVIDENCE_TABLE_REQUEST_SUCCESS';

export const SCHOOL_TYPE_CHANGE = 'app/ProductAdmin/SCHOOL_TYPE_CHANGE';
export const SCHOOL_SIZE_CHANGE = 'app/ProductAdmin/SCHOOL_SIZE_CHANGE';
export const RESET_STATE = 'app/ProductAdmin/RESET_STATE';

export const PRODUCTS_TOGGLE_FILTER = 'app/ProductAdmin/PRODUCTS_TOGGLE_FILTER';
export const PRODUCTS_TOGGLE_SUBJECT = 'app/ProductAdmin/PRODUCTS_TOGGLE_SUBJECT';
export const PRODUCTS_TOGGLE_FEATURE = 'app/ProductAdmin/PRODUCTS_TOGGLE_FEATURE';
export const PRODUCTS_TOGGLE_TYPE = 'app/ProductAdmin/PRODUCTS_TOGGLE_TYPE';

export const GET_PRODUCT = 'app/ProductAdmin/GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'app/ProductAdmin/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_COMPLETE = 'app/ProductAdmin/GET_PRODUCT_COMPLETE';

export const PAYMENT_INTENT = 'app/ProductAdmin/PAYMENT_INTENT';
export const PAYMENT_INTENT_SUCCESS = 'app/ProductAdmin/PAYMENT_INTENT_SUCCESS';
export const PAYMENT_INTENT_COMPLETE = 'app/ProductAdmin/PAYMENT_INTENT_COMPLETE';

export const CONFIRM_PAYMENT = 'app/ProductAdmin/CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_SUCCESS = 'app/ProductAdmin/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/ProductAdmin/CONFIRM_PAYMENT_ERROR';
export const CONFIRM_PAYMENT_COMPLETE = 'app/ProductAdmin/CONFIRM_PAYMENT_COMPLETE';

export const PRODUCTS_TOGGLE_CHECKBOX =
  'app/ProductAdmin/PRODUCTS_TOGGLE_CHECKBOX';
export const UPDATE_PRODUCT = 'app/ProductAdmin/UPDATE_PRODUCT';
export const COMPLETE_UPDATE_PRODUCT = 'app/ProductAdmin/COMPLETE_UPDATE_PRODUCT';
export const PRODUCTS_SELECT_PLAN = 'app/ProductAdmin/SELECT_PLAN';
export const EMAILS_CHANGE = 'app/ProductAdmin/EMAILS_CHANGE';
export const REVIEW_CHANGE = 'app/ProductAdmin/REVIEW_CHANGE';
export const WIDGET_DETAILS_CHANGE = 'app/ProductAdmin/WIDGET_DETAILS_CHANGE';
export const EXPORT_REVIEWS = 'app/ProductAdmin/EXPORT_REVIEWS';
export const EXPORT_ALL_REVIEWS = 'app/ProductAdmin/EXPORT_ALL_REVIEWS';
export const EXPORT_ALL_PRODUCTS = 'app/ProductAdmin/EXPORT_ALL_PRODUCTS';
export const EXPORT_ALL_LEADS = 'app/ProductAdmin/EXPORT_ALL_LEADS';
export const EXPORT_ALL_ANON_USERS = 'app/ProductAdmin/EXPORT_ALL_ANON_USERS';
export const EXPORT_COMPLETE = 'app/ProductAdmin/EXPORT_COMPLETE';
export const UPLOAD_LOGO = 'app/ProductAdmin/UPLOAD_LOGO';
export const UPLOAD_SCREENSHOT = 'app/ProductAdmin/UPLOAD_SCREENSHOT';
export const UPLOAD_EVIDENCE = 'app/ProductAdmin/UPLOAD_EVIDENCE';
export const DELETE_EVIDENCE = 'app/ProductAdmin/DELETE_EVIDENCE';

export const DELETE_SUBSCRIPTION = 'app/ProductAdmin/DELETE_SUBSCRIPTION';

export const CREATE_SUBSCRIPTION = 'app/ProductAdmin/CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'app/ProductAdmin/CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_COMPLETE = 'app/ProductAdmin/CREATE_SUBSCRIPTION_COMPLETE';

export const APPLY_COUPON = 'app/ProductAdmin/APPLY_COUPON';
export const APPLY_COUPON_SUCCESS = 'app/ProductAdmin/APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_COMPLETE = 'app/ProductAdmin/APPLY_COUPON_COMPLETE';

export const schoolTypes = [
  { id: 1, title: 'Primary', value: 'Primary' },
  { id: 2, title: 'Secondary', value: 'Secondary' },
  { id: 3, title: 'International', value: 'International' },
];

export const schoolSizes = [
  {
    id: 1,
    title: '100 students or less',
    value: {
      min: 0,
      max: 100,
    },
  },
  {
    id: 2,
    title: '101-500 students',
    value: {
      min: 101,
      max: 500,
    },
  },
  {
    id: 3,
    title: '501-1000 students',
    value: {
      min: 501,
      max: 1000,
    },
  },
  {
    id: 4,
    title: '1000+ students',
    value: {
      min: 1001,
      max: 100000,
    },
  },
];
