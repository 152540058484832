import * as actionTypes from './constants';

export function updateConfigAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    payload: config,
  };
}

export function updateConfigSuccessAction(config) {
  return {
    type: actionTypes.UPDATE_CONFIG_SUCCESS,
    payload: config,
  };
}

export function updateConfigCompleteAction() {
  return {
    type: actionTypes.UPDATE_CONFIG_COMPLETE,
  };
}

export function getWordPressCategoryDataAction() {
  return {
    type: actionTypes.WP_CATEGORY_DATA,
  };
}

export function getWordPressCategoryDataSuccessAction(data) {
  return {
    type: actionTypes.WP_CATEGORY_DATA_SUCCESS,
    payload: data,
  };
}

export function getWordPressCategoryDataCompleteAction() {
  return {
    type: actionTypes.WP_CATEGORY_DATA_COMPLETE,
  };
}

export function productsRequestAction(productName) {
  return {
    type: actionTypes.PRODUCTS_REQUEST,
    payload: productName,
  };
}

export function productsRequestCompleteAction() {
  return {
    type: actionTypes.PRODUCTS_REQUEST_COMPLETE,
  };
}

export function productsRequestSuccessAction(product) {
  return {
    type: actionTypes.PRODUCTS_REQUEST_SUCCESS,
    payload: product,
  };
}

export function invitesRequestAction(payload) {
  return {
    type: actionTypes.INVITES_REQUEST,
    payload,
  };
}

export function invitesRequestCompleteAction() {
  return {
    type: actionTypes.INVITES_REQUEST_COMPLETE,
  };
}

export function invitesRequestSuccessAction(invites) {
  return {
    type: actionTypes.INVITES_REQUEST_SUCCESS,
    payload: invites,
  };
}

export function invitesUploadAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD,
    payload,
  };
}

export function invitesUploadSuccessAction(payload) {
  return {
    type: actionTypes.INVITES_UPLOAD_SUCCESS,
    payload,
  };
}

export function invitesUploadCompleteAction() {
  return {
    type: actionTypes.INVITES_UPLOAD_COMPLETE,
  };
}

export function reviewsRequestAction(payload) {
  return {
    type: actionTypes.REVIEWS_REQUEST,
    payload,
  };
}

export function reviewsRequestCompleteAction() {
  return {
    type: actionTypes.REVIEWS_REQUEST_COMPLETE,
  };
}

export function reviewsRequestSuccessAction(evidence) {
  return {
    type: actionTypes.REVIEWS_REQUEST_SUCCESS,
    payload: evidence,
  };
}

export function reviewDeleteAction(payload) {
  return {
    type: actionTypes.REVIEW_DELETE,
    payload,
  };
}

export function reviewDeleteCompleteAction() {
  return {
    type: actionTypes.REVIEW_DELETE_COMPLETE,
  };
}

export function reviewDeleteSuccessAction(payload) {
  return {
    type: actionTypes.REVIEW_DELETE_SUCCESS,
    payload,
  };
}

export function reviewReplyAction(payload) {
  return {
    type: actionTypes.REVIEW_REPLY,
    payload,
  };
}

export function reviewReplyCompleteAction() {
  return {
    type: actionTypes.REVIEW_REPLY_COMPLETE,
  };
}

export function reviewReplySuccessAction(review) {
  return {
    type: actionTypes.REVIEW_REPLY_SUCCESS,
    payload: review,
  };
}

export function leadsRequestAction(payload) {
  return {
    type: actionTypes.LEADS_REQUEST,
    payload,
  };
}

export function leadsRequestCompleteAction() {
  return {
    type: actionTypes.LEADS_REQUEST_COMPLETE,
  };
}

export function leadsRequestSuccessAction(evidence) {
  return {
    type: actionTypes.LEADS_REQUEST_SUCCESS,
    payload: evidence,
  };
}

export function setLeadStatusAction({ lead, status }) {
  return {
    type: actionTypes.SET_LEAD_STATUS,
    lead,
    status,
  };
}

export function setLeadStatusCompleteAction() {
  return {
    type: actionTypes.SET_LEAD_STATUS_COMPLETE,
  };
}

export function setLeadStatusSuccessAction(lead) {
  return {
    type: actionTypes.SET_LEAD_STATUS_SUCCESS,
    payload: lead,
  };
}

export function leadReplyAction(payload) {
  return {
    type: actionTypes.LEAD_REPLY,
    payload,
  };
}

export function leadReplyCompleteAction() {
  return {
    type: actionTypes.LEAD_REPLY_COMPLETE,
  };
}

export function leadReplySuccessAction(lead) {
  return {
    type: actionTypes.LEAD_REPLY_SUCCESS,
    payload: lead,
  };
}

export function deleteLeadAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD,
    lead,
  };
}

export function deleteLeadSuccessAction(lead) {
  return {
    type: actionTypes.DELETE_LEAD_SUCCESS,
    lead,
  };
}

export function deleteLeadCompleteAction() {
  return {
    type: actionTypes.DELETE_LEAD_COMPLETE,
  };
}

export function schoolSizeChange(payload) {
  return {
    type: actionTypes.SCHOOL_SIZE_CHANGE,
    payload,
  };
}

export function schoolTypeChange(payload) {
  return {
    type: actionTypes.SCHOOL_TYPE_CHANGE,
    payload,
  };
}

export function productsToggleFilterAction(payload) {
  return {
    type: actionTypes.PRODUCTS_TOGGLE_FILTER,
    payload,
  };
}

export function productsToggleSubjectAction(payload) {
  return {
    type: actionTypes.PRODUCTS_TOGGLE_SUBJECT,
    payload,
  };
}

export function productsToggleFeatureAction(payload) {
  return {
    type: actionTypes.PRODUCTS_TOGGLE_FEATURE,
    payload,
  };
}

export function productsToggleTypeAction(payload) {
  return {
    type: actionTypes.PRODUCTS_TOGGLE_TYPE,
    payload,
  };
}

export function productsToggleCheckboxAction(payload) {
  return {
    type: actionTypes.PRODUCTS_TOGGLE_CHECKBOX,
    payload,
  };
}

export function productsSelectPlanAction(payload) {
  return {
    type: actionTypes.PRODUCTS_SELECT_PLAN,
    payload,
  };
}

export function getProduct(slug) {
  return {
    type: actionTypes.GET_PRODUCT,
    slug,
  };
}

export function resetState() {
  return {
    type: actionTypes.RESET_STATE,
  };
}

export function getProductSuccess(product) {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    product,
  };
}

export function getProductComplete() {
  return {
    type: actionTypes.GET_PRODUCT_COMPLETE,
  };
}

export function updateProductAction({ product, screenshots, logo }) {
  return {
    type: actionTypes.UPDATE_PRODUCT,
    product,
    screenshots,
    logo,
  };
}

export function completeUpdateProduct() {
  return {
    type: actionTypes.COMPLETE_UPDATE_PRODUCT,
  };
}

export function createProductAction({ product, screenshots, logo }) {
  return {
    type: actionTypes.CREATE_PRODUCT,
    product,
    screenshots,
    logo,
  };
}

export function createProductSuccessAction(product) {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
    product,
  };
}

export function createProductCompleteAction(product) {
  return {
    type: actionTypes.CREATE_PRODUCT_COMPLETE,
    product,
  };
}

export function productsEmailsChangeAction(emails) {
  return {
    type: actionTypes.EMAILS_CHANGE,
    emails,
  };
}

export function reviewChange(reviewPreference) {
  return {
    type: actionTypes.REVIEW_CHANGE,
    reviewPreference,
  };
}

export function widgetDetailsChange(detailsPreference) {
  return {
    type: actionTypes.WIDGET_DETAILS_CHANGE,
    detailsPreference,
  };
}

export function exportReviews() {
  return {
    type: actionTypes.EXPORT_REVIEWS,
  };
}

export function exportAllReviews() {
  return {
    type: actionTypes.EXPORT_ALL_REVIEWS,
  };
}

export function exportAllLeads(payload) {
  return {
    type: actionTypes.EXPORT_ALL_LEADS,
    payload,
  };
}

export function exportAllProducts(payload) {
  return {
    type: actionTypes.EXPORT_ALL_PRODUCTS,
    payload,
  };
}

export function exportAllAnonUsers(payload) {
  return {
    type: actionTypes.EXPORT_ALL_ANON_USERS,
    payload,
  };
}

export function exportComplete() {
  return {
    type: actionTypes.EXPORT_COMPLETE,
  };
}

export function uploadLogo(payload) {
  return {
    type: actionTypes.UPLOAD_LOGO,
    payload,
  };
}

export function uploadScreenshot(payload) {
  return {
    type: actionTypes.UPLOAD_SCREENSHOT,
    payload,
  };
}

export function uploadEvidence(payload) {
  return {
    type: actionTypes.UPLOAD_EVIDENCE,
    payload,
  };
}

export function deleteEvidence(payload) {
  return {
    type: actionTypes.DELETE_EVIDENCE,
    payload,
  };
}

export function configRequestAction() {
  return {
    type: actionTypes.CONFIG_REQUEST,
  };
}

export function configRequestSuccessAction(config) {
  return {
    type: actionTypes.CONFIG_REQUEST_SUCCESS,
    payload: config,
  };
}

export function configRequestCompleteAction() {
  return {
    type: actionTypes.CONFIG_REQUEST_COMPLETE,
  };
}

/**
 * @param {object} params
 * @param {integer} params.amount
 * @param {boolean} params.scrollToTop Set to true by default as that has been the default behaviour so far.
 */
export function createPaymentIntentAction({ amount, scrollToTop = true }) {
  return {
    type: actionTypes.PAYMENT_INTENT,
    amount,
    scrollToTop,
  };
}

export function createPaymentIntentSuccessAction(paymentIntent) {
  return {
    type: actionTypes.PAYMENT_INTENT_SUCCESS,
    paymentIntent,
  };
}

export function createPaymentIntentCompleteAction() {
  return {
    type: actionTypes.PAYMENT_INTENT_COMPLETE,
  };
}

export function confirmPaymentAction(payload) {
  return {
    type: actionTypes.CONFIRM_PAYMENT,
    payload,
  };
}

export function confirmPaymentSuccessAction({ newMonetaryBalance, newPaidLeadCount, paymentIntent }) {
  return {
    type: actionTypes.CONFIRM_PAYMENT_SUCCESS,
    newMonetaryBalance,
    newPaidLeadCount,
    paymentIntent,
  };
}

export function confirmPaymentErrorAction(error) {
  return {
    type: actionTypes.CONFIRM_PAYMENT_ERROR,
    error,
  };
}

export function confirmPaymentCompleteAction() {
  return {
    type: actionTypes.CONFIRM_PAYMENT_COMPLETE,
  };
}

export function deleteSubscriptionAction() {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION,
  };
}

export function createSubscriptionAction(payload) {
  return {
    type: actionTypes.CREATE_SUBSCRIPTION,
    payload,
  };
}

export function createSubscriptionSuccessAction(subscription) {
  return {
    type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS,
    subscription,
  };
}

export function createSubscriptionCompleteAction() {
  return {
    type: actionTypes.CREATE_SUBSCRIPTION_COMPLETE,
  };
}

export function applyCouponAction(payload) {
  return {
    type: actionTypes.APPLY_COUPON,
    payload,
  };
}

export function applyCouponSuccessAction(payload) {
  return {
    type: actionTypes.APPLY_COUPON_SUCCESS,
    payload,
  };
}

export function applyCouponCompleteAction() {
  return {
    type: actionTypes.APPLY_COUPON_COMPLETE,
  };
}
