import { takeLatest, takeEvery, all, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from './constants';
import * as actions from './actions';
import {
  selectBrand,
  selectBrands,
} from './selectors';
import { URLToFormData } from '../../utils/helpers';
import ApiService from '../../services/apiService';
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import axios from 'axios';
import moment from 'moment';

const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function* configRequest() {
  try {
    const config = yield call([ApiService, 'getConfig']);
    yield put(actions.configRequestSuccessAction(config));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(actions.configRequestCompleteAction());
  }
}

export function* updateConfig(action) {
  scrollToTop();
  try {
    const config = yield call([ApiService, 'updateConfig'], action.payload);
    yield put(actions.updateConfigSuccessAction(config));
    toast.success("Saved!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.updateConfigCompleteAction());
  }
}

export function* brandRequest() {
  try {
    const result = yield call([ApiService, 'getUserItems'], 'brand');
    yield put(actions.brandRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.brandRequestCompleteAction());
  }
}

export function* selectBrandSaga(action) {
  try {
    let brand;
    if (action.slug === 'add-listing') {
      brand = {slug: 'add-listing', name: '+ Add Listing', plan: 'free'};
    } else {
      brand = yield call([ApiService, 'getUserItem'], action.slug, 'brand');
    }
    yield put(actions.selectBrandSuccess(brand));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.selectBrandComplete());
  }
}

export function* exportReviews() {
  try {
    const brand = yield select(selectBrand);
    const reviewsExport = yield call([ApiService, 'reviewsExport'], {brand: brand.slug});
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllReviews() {
  try {
    const reviewsExport = yield call([ApiService, 'allReviewsExport']);
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "all-reviews.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* exportAllBrands() {
  try {
    const reviewsExport = yield call([ApiService, 'allBrandsExport']);
    const blob = new Blob([reviewsExport], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "all-brands.csv");
    toast.success("Export successful!");
  } catch (err) {
    toast.error("Error.");
    throw err;
  } finally {
    yield put(actions.exportComplete());
  }
}

export function* createBrand(action) {
  scrollToTop();
  try {
    const response = yield call([ApiService, 'createItem'], action.brand, 'brand');

    if (response.data && response.data.error) {
      toast.error(response.data.error.description || "Error. Not created.");

    } else {
      yield put(actions.createBrandSuccessAction(response));
      yield put(push(`/brands/${response.slug}/profile`));
      toast.success("Listing created!");
    }

  } catch (err) {
    console.log(err);
    toast.error(err.description || "Error. Not created.");
  } finally {
    yield put(actions.createBrandCompleteAction());
  }
}

export function* updateBrand(action) {
  scrollToTop();
  try {
    const { slug } = yield select(selectBrand);
    const newBrand = {
      ...action.brand,
      slug,
    };
  
    if (action.logo && action.logo.source) {
      const form = new FormData();
      const file = yield call(URLToFormData, [action.logo.source]);
      form.append('file', file);
      newBrand.logo = yield call([ApiService, 'postLogo'], {form, slug});
    }
  
    const result = yield call([ApiService, 'updateItem'], newBrand, 'brand');
    yield put(actions.selectBrandSuccess(result));
    toast.success("Saved!");
    
  } catch (err) {
    toast.error("Error. Not saved.");
    throw err;
  } finally {
    yield put(actions.completeUpdateBrand());
  }
}

export function* reviewsRequest(action) {
  scrollToTop();

  const brand = action.payload.slug;
  const page = action.payload.page;
  const featured = action.payload.featured;
  const reviewPreference = action.payload.reviewPreference;
  const perPage = action.payload.perPage || 10;

  try {
    const result = yield call([ApiService, 'reviews'], {
      brand,
      page,
      featured,
      reviewPreference,
      perPage,
    });
    yield put(actions.reviewsRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.reviewsRequestCompleteAction());
  }
}

export function* invitesRequest(action) {
  scrollToTop();
  const { page=1, perPage=100, slug } = action.payload;

  try {
    const result = yield call([ApiService, 'getInvites'], {
      slug,
      page,
      perPage,
    });
    yield put(actions.invitesRequestSuccessAction(result));
  } catch (err) {
    throw err;
  } finally {
    yield put(actions.invitesRequestCompleteAction());
  }
}

export function* invitesUpload(action) {
  scrollToTop();
  try {
    const { slug } = yield select(selectBrand);
    const result = yield call([ApiService, 'createInvites'], {
      slug, invites: action.payload.invites, sendDate: action.payload.sendDate,
    });
    yield put(actions.invitesUploadSuccessAction(result));
    toast.success("Success!");
    yield put(push(`/brands/${slug}/reviews/invitations`));
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.invitesUploadCompleteAction());
  }
}

export function* reviewReply(action) {
  try {
    const result = yield call([ApiService, 'replyToReview'], action.payload);
    yield put(actions.reviewReplySuccessAction({
      ...action.payload.review,
      reply: action.payload.reply,
    }));
    toast.success("Success!");
  } catch (err) {
    toast.error("Error.");
    console.log(err);
  } finally {
    yield put(actions.reviewReplyCompleteAction());
  }
}

export default function* watch() {
  yield takeLatest(actionTypes.BRAND_REQUEST, brandRequest);
  yield takeLatest(actionTypes.CONFIG_REQUEST, configRequest);
  yield takeLatest(actionTypes.CREATE_BRAND, createBrand);
  yield takeLatest(actionTypes.UPDATE_BRAND, updateBrand);
  yield takeLatest(actionTypes.SELECT_BRAND, selectBrandSaga);
  yield takeLatest(actionTypes.EXPORT_REVIEWS, exportReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_REVIEWS, exportAllReviews);
  yield takeLatest(actionTypes.EXPORT_ALL_BRANDS, exportAllBrands);
  yield takeLatest(actionTypes.REVIEWS_REQUEST, reviewsRequest);
  yield takeLatest(actionTypes.INVITES_REQUEST, invitesRequest);
  yield takeLatest(actionTypes.INVITES_UPLOAD, invitesUpload);
  yield takeLatest(actionTypes.UPDATE_CONFIG, updateConfig);
  yield takeLatest(actionTypes.REVIEW_REPLY, reviewReply);
}
